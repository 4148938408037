import React, { useEffect, useState } from "react";
import ProjectCard from "./ProjectCard";
import apiCalls from "../Services/AxiosCalls";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import SearchBar from "../Components /SearchBar";
import Select from "react-select";
import CustomModal from "../Components /CustomModal";
import DatePicker from "react-datepicker";
import { useFlashMessage } from "../Components /FlashMessageContext";

const options = [
  { value: "completed", label: "Completed" },
  { value: "inprogress", label: "In-Progress" },
];

const ProjectsList = () => {
  const [filter, setFilter] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [projectName, setProjectName] = useState("");
  const [client, setClient] = useState(null);
  const [crop, setCrop] = useState(null);
  const [description, setDescription] = useState(null);

  const navigate = useNavigate();
  const { showFlashMessage } = useFlashMessage();
  const [projects, setProjects] = useState([]);

  const filteredProjects = projects
    .filter((project) => (filter === "all" ? true : project.status === filter))
    .filter((project) =>
      project.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  useEffect(() => {
    getProjects();
  }, []); // Empty dependency array ensures this runs only once

  const getProjects = async () => {
    const response = await apiCalls.getProject();
    setProjects(response.data);
  };

  const handleAddStation = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let project = {
        crop: crop,
        name: projectName,
        client: client,
        description: description,
      };

      const result = await apiCalls.postProject(project);

      if (result.status === 201) {
        showFlashMessage(
          `Project ${projectName} saved successfully`,
          "success"
        );
        setProjects((prevProjects) => [...prevProjects, result.data]);
        handleCloseModal();
      }

      console.log("Result:", result);
    } catch (error) {
      console.error("Error saving project:", error);
      showFlashMessage("Error saving project. Please try again.", "error");
    }
  };

  const handleProjectDetail = async (projID, project) => {
    try {
      navigate(`/project/${projID}`, { state: { project, projectName } });
    } catch (error) {
      console.error("Error navigating to project detail:", error);
      showFlashMessage("Error Navigating to Project Detail!", "danger");
    }
  };

  return (
    <div className="project-list-container">
      <div>
        <div className="d-flex justify-content-end mb-3 ">
          <Button
            variant="success"
            onClick={handleAddStation}
            style={{ marginLeft: 12 }}
          >
            Create New
          </Button>
        </div>
        <Row>
          <Col xs={6} md={4} lg={4}>
            <SearchBar
              placeholder="Search by Project Name..."
              onChange={(value) => setSearchTerm(value)}
            />
          </Col>
        </Row>
      </div>
      <CustomModal
        show={showModal}
        onHide={handleCloseModal}
        title="Create Project"
        content={
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Project Name</Form.Label>
              <Form.Control
                type="text"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Client</Form.Label>
              <Form.Control
                type="text"
                value={client}
                onChange={(e) => setClient(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Crop</Form.Label>
              <Form.Control
                type="text"
                value={crop}
                onChange={(e) => setCrop(e.target.value)}
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
          </Form>
        }
        btnName="Save"
        onSave={handleSubmit}
      />
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
        {filteredProjects.map((project) => (
          <div key={project.id} className="col">
            <ProjectCard
              project={project}
              onClick={() => handleProjectDetail(project.id, project)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectsList;
