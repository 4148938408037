import React, { useEffect } from 'react';
import { useFlashMessage } from './FlashMessageContext'; // Adjust the import path
import { CSSTransition } from 'react-transition-group';
import Alert from 'react-bootstrap/Alert';
import '../Styles/FlashMessage.css'; // Import your custom CSS for the transition



const FlashMessage = () => {
    const { flashMessage, hideFlashMessage } = useFlashMessage();
  
    useEffect(() => {
      if (flashMessage) {
        // Automatically hide the flash message after a certain time (e.g., 3000ms)
        const timer = setTimeout(() => hideFlashMessage(), 3000);
        return () => clearTimeout(timer);
      }
    }, [flashMessage, hideFlashMessage]);
  
    if (!flashMessage) {
      return null;
    }
  
    const { message, variant } = flashMessage;
  
    return (
      <CSSTransition in={!!flashMessage} classNames="flash-message" timeout={300} unmountOnExit>
        <Alert variant={variant} onClose={() => hideFlashMessage()} dismissible>
          {message}
        </Alert>
      </CSSTransition>
    );
  };
  
  export default FlashMessage;
  
