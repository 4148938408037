import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { PDFViewer, Document } from "@react-pdf/renderer";
import { saveAs } from "file-saver"; // For file download
import {
  Row,
  Col,
  Form,
  Spinner,
  Button,
  Stack,
  ProgressBar,
} from "react-bootstrap";

const PDFReport = ({ dataArray }) => {
  const [pageCount, setPageCount] = useState(0);
  const [generatedPages, setGeneratedPages] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);

  const handleWorkerMessage = (e) => {
    if (e.data.type === "progress") {
      setProgress(e.data.value);
    } else if (e.data.type === "complete") {
      saveAs(e.data.blob, "seed_analysis_report.pdf");
      setIsGenerating(false);
      setProgress(0);
    } else if (e.data.type === "error") {
      setError(e.data.error);
      setIsGenerating(false);
      setProgress(0);
    }
  };

  const generateAndDownloadPDF = useCallback(() => {
    setIsGenerating(true);
    setError(null);

    const worker = new Worker(new URL("./pdfWorker.js", import.meta.url));
    worker.onmessage = handleWorkerMessage;

    const batchSize = 40; // Process 50 items at a time to avoid memory issues
    const batches = Math.ceil(dataArray.length / batchSize);

    for (let i = 0; i < batches; i++) {
      const startIndex = i * batchSize;
      const endIndex = Math.min(startIndex + batchSize, dataArray.length);
      worker.postMessage({ dataArray, startIndex, endIndex });
    }
  }, [dataArray]);

  return (
    <div>
      <Button onClick={generateAndDownloadPDF} disabled={isGenerating}>
        {isGenerating ? "Generating PDF..." : "Download PDF Report"}
      </Button>
      {/* {isGenerating && <p>Progress: {progress.toFixed(2)}%</p>} */}
      {isGenerating && <ProgressBar now={progress.toFixed(2)} label={`${progress.toFixed(2)}%`} />}
      {error && <p>Error: {error}</p>}
      {/* <PDFViewer width="100%" height="600">
        {generatedPages.length > 0 && (
          <Document>
            {generatedPages}
          </Document>
        )}
      </PDFViewer> */}
    </div>
  );
};

PDFReport.propTypes = {
  dataArray: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PDFReport;