import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { faChartBar, faDownload } from "@fortawesome/pro-light-svg-icons";

export const CustomToggleList = ({ columns, onColumnToggle, toggles }) => (
  <Row>
    {columns
      .map((column, index) => ({
        ...column,
        toggle: toggles[column.dataField],
      }))
      .map((column, index) => (
        <Col xs={2} key={column.dataField} className="mb-2">
          <Button
            type="button"
            className={`btn ${column.toggle ? "active" : ""}`}
            onClick={() => onColumnToggle(column.dataField)}
            style={{
              width: "50%",
              backgroundColor: "#4E73DF",
            }}
          >
            {column.text}
          </Button>
        </Col>
      ))}
  </Row>
);

export const MyExportCSV = ({ onExportClick, onGraphClick }) => {
  return (
    <Row>
      <Col>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Button
            type="button"
            onClick={onExportClick}
            style={{
              // width: "50%",
              backgroundColor: "#4E73DF",
              marginRight: "13px" 
            }}
          >
            Export
          </Button>
          <Button
            type="button"
            onClick={onGraphClick}
            style={{
              // width: "50%",
              backgroundColor: "#4E73DF",
              marginRight: "13px" 
            }}
          >
            Chart
          </Button>
          {/* <FontAwesomeIcon
            icon={faDownload}
            style={{ marginRight: "13px" }}
            size="xl" className="mr-2"
            onClick={onExportClick}
          ></FontAwesomeIcon>
          
          <FontAwesomeIcon
            icon={faChartBar}
            style={{ marginRight: "5px" }}
            size="xl" className="mr-2"
            onClick={onGraphClick}
          ></FontAwesomeIcon> */}
        </div>
      </Col>
    </Row>
  );
};

export const exportCSV = (data) => {
  if (!data || data.length === 0) {
    return null;
  }

  // Extract column headers from the keys of the first object
  const headers = Object.keys(data[0]);

  // Extract row data
  const rows = data.map((row) => Object.values(row));

  // Prepend headers to rows
  const csvData = [headers, ...rows];

  // Convert to CSV format
  const csvContent = csvData.map((row) => row.join(",")).join("\n");

  // Create a Blob object from the CSV data
  const blob = new Blob([csvContent], { type: "text/csv" });

  // Create a URL for the Blob object
  const url = window.URL.createObjectURL(blob);

  // Create a temporary link element
  const link = document.createElement("a");
  link.href = url;
  link.download = "exported_data.csv"; // Set the filename for the downloaded file

  // Simulate a click event on the link to trigger the download
  document.body.appendChild(link);
  link.click();

  // Remove the temporary link element
  document.body.removeChild(link);

  // Revoke the URL to release the resources
  window.URL.revokeObjectURL(url);
};
