import React from "react";
import { Col, Image, Row } from "react-bootstrap";

const ImageStats = ({ stats, records, image_in, image_out }) => {
  const renderColorDot = (color) => {
    return (
      <span
        className="color-dot"
        style={{
          backgroundColor: color,
          borderRadius: "50%",
          display: "inline-block",
          width: "15px",
          height: "15px",
          marginRight: "5px",
        }}
      ></span>
    );
  };

  // Calculate the most common color
  const calculateCommonColor = () => {
    const colors = records.records.map((record) => record.color);
    const colorCounts = colors.reduce((acc, color) => {
      acc[color] = (acc[color] || 0) + 1;
      return acc;
    }, {});
    const mostCommonColor = Object.keys(colorCounts).reduce((a, b) =>
      colorCounts[a] > colorCounts[b] ? a : b
    );
    return mostCommonColor;
  };

  const mostCommonColor = calculateCommonColor();

  return (
    <div>
      <h3>Image Statistics</h3>
      <Row>
        <Col>
          <p>Image In:</p>
          <Image src={image_in} fluid />
        </Col>
        <Col>
          <p>Image Out:</p>
          <Image src={image_out} fluid />
        </Col>
      </Row>
      <table className="table">
        <thead>
          <tr>
            <th>Parameter</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(stats).map(([param, value]) => (
            <tr key={param}>
              <td>{param}</td>
              <td>{value !== null ? value : "N/A"}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <h3>Seed Data</h3>
      <h4>Common Color: {renderColorDot(mostCommonColor)}</h4>{" "}
      {/* Display the most common color */}
      {records.records.map((record, index) => (
        <div key={index} className="card mb-3">
          <div className="card-body">
            <h5 className="card-title">Seed {index + 1}</h5>
            <p className="card-text">Area: {record.area}</p>
            <p className="card-text">Color: {renderColorDot(record.color)}</p>
            <p className="card-text">Width: {record.width}</p>
            <p className="card-text">Length: {record.length}</p>
            <p className="card-text">
              Box Coordinates: {JSON.stringify(record.box)}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImageStats;
