import React, { createContext, useContext, useState } from 'react';

const FlashMessageContext = createContext();

export const useFlashMessage = () => {
  return useContext(FlashMessageContext);
};

export const FlashMessageProvider = ({ children }) => {
  const [flashMessage, setFlashMessage] = useState(null);

  const showFlashMessage = (message, variant) => {
    setFlashMessage({ message, variant });
    // Automatically hide the flash message after a certain time (e.g., 3000ms)
    setTimeout(() => hideFlashMessage(), 10000);
  };

  const hideFlashMessage = () => {
    setFlashMessage(null);
  };

  return (
    <FlashMessageContext.Provider
      value={{
        flashMessage,
        showFlashMessage,
        hideFlashMessage,
      }}
    >
      {children}
    </FlashMessageContext.Provider>
  );
};
