import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Styles/Datatable.css'
const DEFAULT_PAGE_SIZE = 10;
const AVAILABLE_PAGE_SIZES = [5, 10, 15, 20]; // Available page sizes

const DataTable = ({ headers, data, onRowClick }) => {
  console.log(data);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    setCurrentPage(1); // Reset to the first page when searching
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when changing page size
  };

  // Ensure that filteredData is always an array
  const filteredData = data ? data.filter((row) => {
    return headers.some(
      (header) =>
        row[header] &&
        row[header].toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
  }) : [];

  // Calculate the number of pages
  const totalPages = Math.ceil(filteredData.length / pageSize);

  // Calculate the start and end indices for the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const currentPageData = filteredData.slice(startIndex, endIndex);

  const canGoToPreviousPage = currentPage > 1;
  const canGoToNextPage = currentPage < totalPages;

  return (
    <div>
      <div className="datatable-header">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
        />
        <select
          value={pageSize}
          onChange={handlePageSizeChange}
          className="page-size-select"
        >
          {AVAILABLE_PAGE_SIZES.map((size) => (
            <option key={size} value={size}>
              {size} per page
            </option>
          ))}
        </select>
      </div>
      {currentPageData.length === 0 ? (
        <div className="no-data">
          NO DATA FOUND
        </div>
      ) : (
        <Table striped bordered hover className="custom-table">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{capitalize(header)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentPageData.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                onClick={() => onRowClick(row)}
                className="clickable-row"
              >
                {headers.map((header, headerIndex) => (
                  <td key={headerIndex}>{row[header]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={!canGoToPreviousPage}
          className="page-button"
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={!canGoToNextPage}
          className="page-button"
        >
          Next
        </button>
      </div>
    </div>
  );
};

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default DataTable;
