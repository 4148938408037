import React, { useEffect, useState } from "react";
import { Offcanvas, Form, Button, Alert } from "react-bootstrap";
import Select from "react-select";
import apiCalls from "../../Services/AxiosCalls";

const UpdateInventory = ({ show, handleClose, data, fetchData }) => {
  console.log(show);
  const [formData, setFormData] = useState(data);

  const [storage_facilities, setStorageFacilities] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [selectedRack, setSelectedRack] = useState(null);
  const [selectedShelf, setSelectedShelf] = useState(null);
  const [selectedBox, setSelectedBox] = useState(null);
  const [error, setError] = useState(null);
  const [amount_optons, setAmountOptons] = useState([]);
  const [seedData, setSeedData] = useState(data);
  const [success, setSuccess] = useState(false);

  const weightUnits = [
    { label: "micrograms", value: "micrograms" },
    { label: "milligrams", value: "milligrams" },
    { label: "grams", value: "grams" },
    { label: "kilograms", value: "kilograms" },
    { label: "metric tons", value: "metric tons" },
    { label: "grains", value: "grains" },
    { label: "drachms", value: "drachms" },
    { label: "ounces", value: "ounces" },
    { label: "pounds", value: "pounds" },
    { label: "stones", value: "stones" },
    { label: "quarters", value: "quarters" },
    { label: "hundredweights", value: "hundredweights" },
    { label: "short tons", value: "short tons" },
    { label: "long tons", value: "long tons" },
  ];

  const handleSelectChange = (selectedOption, fieldName) => {
    setFormData({ ...formData, [fieldName]: selectedOption });
  };
  useEffect(() => {
    const fetchStorageFacilities = async () => {
      //   setLoading(true);
      try {
        const response = await apiCalls.getStorageFacility();
        setStorageFacilities(response.data);
        console.log(data);
      } catch (error) {
        setError("Failed to fetch storage facilities.");
      } finally {
        // setLoading(false);
      }
    };

    fetchStorageFacilities();
    // seedAmountOptions();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Wait for all promises to resolve
      const updateInventoryResponse = await apiCalls.updateInventory(
        formData.id,
        formData
      );
      console.log(updateInventoryResponse);
      setSuccess(true);
    } catch (error) {
      // Handle any errors
      console.error("Error saving inventory:", error);
    } finally {
      handleClose();
      fetchData()
    }

    // handleClose();
  };

  const handleFacilityChange = (selectedOption) => {
    setSelectedFacility(selectedOption);
    setSelectedRack(null);
    setSelectedShelf(null);
    setSelectedBox(null);
  };

  const handleRackChange = (selectedOption) => {
    setSelectedRack(selectedOption);
    setSelectedShelf(null);
    setSelectedBox(null);
  };
  const handleAmount = (selectedOption) => {
    setFormData({ ...formData, seed_amount: selectedOption.value });

    console.log(selectedOption);
  };

  const handleShelfChange = (selectedOption) => {
    setSelectedShelf(selectedOption);
    setFormData({
      ...formData,
      location_shelf: selectedOption.value,
      location_box: null,
    });

    setSelectedBox(null);
  };

  const handleBoxChange = (selectedOption) => {
    setSelectedBox(selectedOption);
    setFormData({
      ...formData,
      location_box: selectedOption.value,
      location_shelf: null,
    });
  };
  const handleUnitChange = (selectedOption) => {
    setFormData({
      ...formData,
      unit: selectedOption.value,
    });
  };

  return (
    <Offcanvas
      show={true}
      onHide={handleClose}
      style={{ width: "60%", borderRadius: "20px" }}
      keyboard={true}
      onExited={fetchData}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Update Inventory</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body
        style={{ maxHeight: "calc(100vh - 120px)", overflowY: "auto" }}
      >
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">Rack saved successfully!</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Storage Facility</Form.Label>
            {/* Replace options with actual data */}
            <Select
              value={selectedFacility}
              onChange={handleFacilityChange}
              options={storage_facilities.map((facility) => ({
                value: facility.id,
                label: facility.name,
              }))}
              placeholder="Select Facility"
            />
          </Form.Group>
          {selectedFacility && (
            <Form.Group className="mb-3">
              <Select
                value={selectedRack}
                onChange={handleRackChange}
                options={storage_facilities
                  .find((facility) => facility.id === selectedFacility.value)
                  ?.racks.map((rack) => ({ value: rack.id, label: rack.name }))}
                placeholder="Select Rack"
              />
            </Form.Group>
          )}
          {selectedRack && (
            <Form.Group className="mb-3">
              <Select
                value={selectedShelf}
                onChange={handleShelfChange}
                options={storage_facilities
                  .find((facility) => facility.id === selectedFacility.value)
                  ?.racks.find((rack) => rack.id === selectedRack.value)
                  ?.shelves.map((shelf) => ({
                    value: shelf.id,
                    label: `${shelf.name}`,
                  }))}
                placeholder="Select Shelf"
              />
            </Form.Group>
          )}
          {selectedShelf && (
            <Form.Group className="mb-3">
              <Select
                value={selectedBox}
                onChange={handleBoxChange}
                options={storage_facilities
                  .find((facility) => facility.id === selectedFacility.value)
                  ?.racks.find((rack) => rack.id === selectedRack.value)
                  ?.shelves.find((shelf) => shelf.id === selectedShelf.value)
                  ?.boxes.map((box) => ({
                    value: box.id,
                    label: `Box number ${box.number}`,
                  }))}
                placeholder="Select Box"
              />
            </Form.Group>
          )}

          <Form.Group className="mb-3">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              // as="number"
              type="number"
              value={formData.seed_amount}
              onChange={(e) =>
                setFormData({ ...formData, seed_amount: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Threshold</Form.Label>
            <Form.Control
              // as="number"
              type="number"
              value={formData.threshold}
              onChange={(e) =>
                setFormData({ ...formData, threshold: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Units</Form.Label>
            {/* Replace options with actual data */}
            <Select
              value={weightUnits.find(
                (option) => option.value === formData.unit
              )}
              onChange={handleUnitChange}
              options={weightUnits}
              placeholder="Select Unit"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as="textarea"
              value={formData.notes}
              onChange={(e) =>
                setFormData({ ...formData, notes: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Expiring Date</Form.Label>
            <Form.Control
              type="date"
              value={formData.expiring_date}
              onChange={(e) =>
                setFormData({ ...formData, expiring_date: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Seed Info</Form.Label>
            <Form.Control
              as="textarea"
              value={formData.seed_info}
              onChange={(e) =>
                setFormData({ ...formData, seed_info: e.target.value })
              }
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default UpdateInventory;
