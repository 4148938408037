import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import DataTable from "react-data-table-component";
import { Modal, Button, Offcanvas, Image, ProgressBar } from "react-bootstrap";
import ImageStats from "./ImageStats";
import { exportImagesToZip } from "./exportImagesToZip";
import { saveAs } from "file-saver"; // For file download

const InteractiveTable = forwardRef(({ data, filterData, exportData }, ref) => {
  const linkRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [progress, setProgress] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleFilter = () => {
      const text = filterData.toLowerCase();
      setFilterText(text);

      const filteredItems = data.filter((item) => {
        return columns.some((col) => {
          const value = col.selector ? col.selector(item) : "";
          return String(value).toLowerCase().includes(text);
        });
      });

      setFilteredData(filteredItems);
    };
    handleFilter();
  }, [filterData, data]);

  const handleWorkerMessage = (e) => {
    if (e.data.type === "progress") {
      setProgress(e.data.value);
    } else if (e.data.type === "complete") {
      saveAs(e.data.blob, "seed_analysis_report.pdf");
      setIsGenerating(false);
      setProgress(0);
    } else if (e.data.type === "error") {
      setError(e.data.error);
      setIsGenerating(false);
      setProgress(0);
    }
  };

  const generatePDF = useCallback(() => {
    setIsGenerating(true);
    setError(null);

    const worker = new Worker(new URL("./pdfWorker.js", import.meta.url));
    worker.onmessage = handleWorkerMessage;

    const batchSize = 40;
    const dataToExport = selectedRows?.length > 0 ? selectedRows : filteredData;

    const batches = Math.ceil(dataToExport.length / batchSize);

    for (let i = 0; i < batches; i++) {
      const startIndex = i * batchSize;
      const endIndex = Math.min(startIndex + batchSize, dataToExport.length);
      worker.postMessage({ dataArray: dataToExport, startIndex, endIndex });
    }
  }, [selectedRows, filteredData]);

  useImperativeHandle(ref, () => ({
    downloadImages: async () => {
      const dataToExport =
        selectedRows?.length > 0 ? selectedRows : filteredData;
      await exportImagesToZip(dataToExport);
    },
    downloadCSV: () => {
      handleExportCSV();
    },
    generatePDF: () => {
      generatePDF();
    },
  }));

  const handleRowSelected = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const handleExportCSV = () => {
    const headers = columns.map((col) => col.name).join(",");
    const exportData = selectedRows?.length > 0 ? selectedRows : filteredData;
    const rows = exportData.map((row) =>
      columns.map((col) => (col.selector ? col.selector(row) : "")).join(",")
    );
    const csvContent = [headers, ...rows].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    a.click();
  };

  const columns = [
    {
      name: "Weight-In",
      selector: (row) => row.image_data.weight,
      sortable: true,
    },
    {
      name: "k10",
      selector: (row) => row.image_data?.all_data_out?.stats?.k10 ?? "N/A",
      sortable: true,
    },
    {
      name: "s15",
      selector: (row) => row.image_data?.all_data_out?.stats?.s15 ?? "N/A",
      sortable: true,
    },
    {
      name: "tkw",
      selector: (row) => row.image_data?.all_data_out?.stats?.tkw ?? "N/A",
      sortable: true,
    },
    {
      name: "count",
      selector: (row) => row.image_data?.all_data_out?.stats?.count ?? "N/A",
      sortable: true,
    },
    {
      name: "standard width",
      selector: (row) =>
        row.image_data?.all_data_out?.stats?.std_width ?? "N/A",
      sortable: true,
    },
    {
      name: "average length",
      selector: (row) =>
        row.image_data?.all_data_out?.stats?.avg_length ?? "N/A",
      sortable: true,
    },
    {
      name: "average surface",
      selector: (row) =>
        row.image_data?.all_data_out?.stats?.avg_surface ?? "N/A",
      sortable: true,
    },
    {
      name: "average width",
      selector: (row) =>
        row.image_data?.all_data_out?.stats?.avg_width ?? "N/A",
      sortable: true,
    },
    {
      name: "Images",
      cell: (row) => (
        <div>
          <a href={row.image_data.image_out} download="download">
            <img
              // onClick={() => handleImageClick(row.image_data.image_out)}
              src={row.image_data.image_out}
              width="50"
              alt="Image Out"
            />
          </a>
        </div>
      ),
      sortable: false,
    },
  ];

  const firstDataObj = data[0];
  if (firstDataObj) {
    const dataDictKeys = Object.keys(firstDataObj.data_dict);
    const hasCSI = dataDictKeys.includes("CSI");

    if (hasCSI) {
      columns.unshift(
        { name: "CSI", selector: (row) => row.data_dict.CSI, sortable: true },
        { name: "Farm", selector: (row) => row.data_dict.Farm, sortable: true },
        {
          name: "Trial",
          selector: (row) => row.data_dict.Trial,
          sortable: true,
        },
        { name: "Year", selector: (row) => row.data_dict.Year, sortable: true },
        { name: "Plot", selector: (row) => row.data_dict.Plot, sortable: true },
        { name: "Lot Number", selector: (row) => row.data_dict.Lot_Number, sortable: true }


      );
    } else {
      columns.unshift({
        name: "Item",
        selector: (row) => row.item,
        sortable: true,
      });
    }
  }

  const ExpandedComponent = ({ data }) => {
    const records = data.image_data?.all_data_out.records || [];
    return <DataTable columns={columnsRecords} data={records} dense />;
  };

  const columnsRecords = [
    {
      name: "Color",
      selector: (row) => row.color,
      cell: (row) => (
        <div
          style={{
            backgroundColor: row.color,
            borderRadius: "50%",
            width: "20px",
            height: "20px",
          }}
        ></div>
      ),
      sortable: true,
    },
    { name: "Area", selector: (row) => row.area, sortable: true },
    { name: "Width", selector: (row) => row.width, sortable: true },
    { name: "Length", selector: (row) => row.length, sortable: true },
  ];

  return (
    <div className="container-fluid">
      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        selectableRows
        onSelectedRowsChange={handleRowSelected}
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        highlightOnHover
        responsive
        theme="solarized"
        striped
      />
      {selectedRow && (
        <Offcanvas
          show={showFilter}
          onHide={() => setShowFilter(false)}
          placement="end"
        >
          <Offcanvas.Header closeButton />
          <Offcanvas.Body style={{ overflowY: "auto", maxHeight: "100vh" }}>
            <ImageStats
              image_in={selectedRow.image_data.image_in}
              image_out={selectedRow.image_data.image_out}
              stats={selectedRow.image_data.stats}
              records={selectedRow.image_data.all_data_out}
            />
          </Offcanvas.Body>
        </Offcanvas>
      )}
      <a ref={linkRef} style={{ display: "none" }} />
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedImage && <Image src={selectedImage} fluid />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {isGenerating && <ProgressBar now={progress} label={`${progress}%`} />}
      {error && <p>Error: {error}</p>}
    </div>
  );
});

export default InteractiveTable;
