import React, { useState, useMemo } from "react";
import { Table, Button, Collapse, Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faSort,
  faSortUp,
  faSortDown,
  faFileCsv,
} from "@fortawesome/free-solid-svg-icons";
import { useTable, useSortBy } from "react-table";
import { CSVLink } from "react-csv";
import "bootstrap/dist/css/bootstrap.min.css";

// Component to display individual records in a sub-table
const RecordTable = ({ records }) => (
  <Table striped bordered hover size="sm" className="mb-0">
    <thead>
      <tr>
        {/* <th>ID</th> */}
        <th>Port</th>
        <th>Value</th>
        <th>Unit</th>

        <th>Notes</th>
        {/* <th>Process</th> */}

        {/* <th>User</th> */}
        {/* <th>Created At</th>
        <th>Updated At</th> */}
      </tr>
    </thead>
    <tbody>
      {records.map((record, idx) => (
        <tr key={idx}>
          {/* <td>{record.id}</td> */}
          <td>{record.port.name}</td>
          <td>{record.value_num || record.value_text}</td>
          <td>{record.unit}</td>

          <td>{record.notes}</td>
          {/* <td>{record.process.id}</td> */}
          {/* <td>{record.user}</td>
          <td>{new Date(record.created_at).toLocaleString()}</td>
          <td>{new Date(record.updated_at).toLocaleString()}</td> */}
        </tr>
      ))}
    </tbody>
  </Table>
);

// Component to display Stations and Blocks in a sub-table
const StationBlockTable = ({ data }) => (
  <Table striped bordered hover size="sm" className="mb-0">
    <thead>
      <tr>
        <th>Station</th>
        <th>Block</th>
        <th>Records</th>
      </tr>
    </thead>
    <tbody>
      {data.map((station, index) => (
        <tr key={index}>
          <td>{station.station}</td>
          <td>{station.block}</td>
          <td>
            <RecordTable records={station.records} />
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

// Main table component
const ClickableTable = ({ data, onRowClick }) => {
  const [openRow, setOpenRow] = useState(null);

  const toggleRow = (index) => {
    setOpenRow(openRow === index ? null : index);
  };

  const columns = useMemo(
    () => [
      
      {
        Header: "Item Name",
        accessor: "Item",
      },
      {
        Header: "Task Name",
        accessor: "task_name",
      },
      {
        Header: "Records",
        accessor: (row) => row.data.length,
      },
      // {
      //   Header: "Blocks",
      //   accessor: (row) =>
      //     row.data.reduce((count, station) => count + 1, 0),
      // },
      {
        Header: "",
        accessor: "expand",
        disableSortBy: true,
        Cell: ({ row }) => (
          <Button
            variant="link"
            onClick={(e) => {
              e.stopPropagation(); // Prevent row click from triggering
              toggleRow(row.id);
            }}
            aria-controls={`collapse-${row.id}`}
            aria-expanded={openRow === row.id}
          >
            <FontAwesomeIcon
              icon={openRow === row.id ? faChevronUp : faChevronDown}
            />
          </Button>
        ),
      },
    ],
    [openRow]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  // CSV Data Preparation
  const csvData = useMemo(() => {
    let flattenedData = [];
    data.forEach((item) => {
      item.data.forEach((station) => {
        station.records.forEach((record) => {
          flattenedData.push({
            Item: item.Item,
            "Task Name": item.task_name,
            Station: station.station,
            Block: station.block,
            Port: record.port.name,
            "Value": record.value_num ? record.value_num : record.value_text,
            Unit: record.unit,
           

            // "Record ID": record.id,
            Notes: record.notes,
            // "Value Number": record.value_num,
            // "Value Text": record.value_text,
            // Process: record.process.id,
            // User: record.user,
            // "Created At": new Date(record.created_at).toLocaleString(),
            // "Updated At": new Date(record.updated_at).toLocaleString(),
          });
        });
      });
    });
    return flattenedData;
  }, [data]);

  const csvHeaders = [
    { label: "Item", key: "Item" },
    { label: "Task Name", key: "Task Name" },
    { label: "Station", key: "Station" },
    { label: "Block", key: "Block" },
    { label: "Port", key: "Port" },
    { label: "Unit", key: "Unit" },
    { label: "Value", key: "Value" },


    // { label: "Record ID", key: "Record ID" },
    { label: "Notes", key: "Notes" },
    // { label: "Value Text", key: "Value Text" },
    // { label: "Process", key: "Process" },
    // { label: "User", key: "User" },
    // { label: "Created At", key: "Created At" },
    // { label: "Updated At", key: "Updated At" },
  ];

  return (
    <Container fluid>
      <Row>
        <Col className="d-flex justify-content-end mb-2">
          <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename={"table-data.csv"}
            className="btn btn-primary"
          >
            <FontAwesomeIcon icon={faFileCsv} /> Export to CSV
          </CSVLink>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover {...getTableProps()} responsive>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(
                        column.getSortByToggleProps()
                      )}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon icon={faSortDown} />
                          ) : (
                            <FontAwesomeIcon icon={faSortUp} />
                          )
                        ) : (
                          <FontAwesomeIcon icon={faSort} />
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <React.Fragment key={index}>
                    <tr
                      {...row.getRowProps()}
                      // onClick={() => onRowClick(row.original)}
                    >
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                    {openRow === row.id && (
                      <tr>
                        <td colSpan={columns.length} className="p-0">
                          <Collapse in={openRow === row.id}>
                            <div id={`collapse-${row.id}`}>
                              <StationBlockTable data={row.original.data} />
                            </div>
                          </Collapse>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default ClickableTable;
