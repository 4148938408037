import React, { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  Offcanvas,
  Row,
  Stack,
} from "react-bootstrap";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import apiCalls from "../../Services/AxiosCalls";
import TimelineComponent from "../../Components /TimelineComponent";
import ConfirmationModal from "../../Components /ConfirmationModal";
import UpdateInventory from "./inventoryUpdate";
import QRScannerModal from "../../Components /QRScannerModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faQrcode } from "@fortawesome/free-solid-svg-icons";

const Transactions = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [formData, setFormData] = useState({
    inventory: "",
    transaction_type: "",
    amount_changed: "",
    notes: "",
  });
  const [error, setError] = useState("");
  const [successmsg, setSuccessmsg] = useState("");

  const [success, setSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null); // Track the item to delete
  const [showEditCanvas, setShowEditCanvas] = useState(false);
  const [filteresData, setFilteresData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [params, setParams] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [openCamera, setOpenCamera] = useState(false);

  const getExtractedFilteres = async () => {
    try {
      const response = await apiCalls.ExtractFilteres();
      console.log(response.data);
      setFilteresData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };
  const toggleCamera = () => {
    setOpenCamera(!openCamera);
  };

  const getScanData = async (data) => {};

  const handleGetSelectedOptions = async (data) => {
    try {
      const BL_params = {
        crop: params.crop?.value,
        CSI: params.csi?.value,
        farm: params.farm?.value,
        trial: params.trial?.value,
        year: params.year?.value,
        location: params.location?.value,
        trial_type: params.trial_type?.value,
        location_type: data?.key,
        location_id: data?.id,
      };

      const filterResponse = await apiCalls.InventoryFIlters(BL_params);

      if (filterResponse.status === 200) {
        setInventoryData(filterResponse.data);
        console.log(filterResponse);
        // toggleFilter();
        setShowFilter(false);
      } else {
        throw new Error("Failed to fetch filtered inventory data");
      }
    } catch (error) {
      console.error("Error fetching filtered inventory data:", error);
      // Handle error state or display error message to the user
    }
  };

  const fetchData = async () => {
    try {
      const data = await apiCalls.getInventory();
      setInventoryData(data.data);
    } catch (error) {
      console.error("Error fetching inventory data:", error);
    }
  };

  useEffect(() => {
    getExtractedFilteres();
    fetchData();
  }, []);

  const options = [
    { value: "INCREASE", label: "INCREASE" },
    { value: "DECREASE", label: "DECREASE" },
  ];

  const handleRowClick = async (row) => {
    console.log(row);
    try {
      const data = await apiCalls.getInventoryTransactionbyInventoryID(row.id);
      console.log(data);

      setTransactionData(data.data);
      setFormData((prevFormData) => ({
        ...prevFormData,
        inventory: row.id,
      }));
      setSelectedRow(row);
      setShowOffcanvas(true);
    } catch (error) {
      console.error("Error fetching inventory transaction data:", error);
    }
  };

  const closeOffcanvas = () => {
    setShowOffcanvas(false);
  };
  const closeEditcanvas = () => {
    setShowEditCanvas(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTypeChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      transaction_type: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);
    try {
      // Send form data to backend
      console.log(formData);
      const response = await apiCalls.postInventoryTransaction(formData);
      setSuccess(true);
      setSuccessmsg("Transaction was successfull");

      console.log(response);
      // Clear form data after submission
      setFormData({
        inventory: "",
        transaction_type: "",
        amount_changed: "",
        notes: "",
      });
    } catch (error) {
      console.error("Error submitting form data:", error);
      setError(error.response.message || "Failed to perform transaction");
    } finally {
      fetchData();
    }
  };

  const handleEdit = (formData) => {
    setShowEditCanvas(true);
  };

  const handleDelete = async (formData) => {
    try {
      const response = await apiCalls.deleteInventory(formData.id);
      console.log(response);
      setSuccess(true);
      setShowModal(false);
      setSuccessmsg("Inventory Deleted");
    } catch (error) {
      console.error("Error submitting form data:", error);
      setError(error.response.message || "Failed to perform transaction");
    } finally {
      fetchData();
    }
  };

  const handleDeleteConfirm = () => {
    if (itemToDelete) {
      handleDelete(itemToDelete);
    }
  };

  const columns = [
    { dataField: "item_name", text: "Item", filter: textFilter() },
    {
      dataField: "location_name",
      text: "Storage Location",
      filter: textFilter(),
    },
    {
      dataField: "seed_amount",
      text: "Seed Amount",
      formatter: (cell, row) => parseFloat(cell).toFixed(4),
      filter: textFilter(),
    },
    { dataField: "unit", text: "Unit" },

    { dataField: "notes", text: "Notes" },
    { dataField: "status", text: "Status", filter: textFilter() },
    {
      dataField: "actions",
      text: "Actions",
      formatter: (cell, row) => (
        <div>
          <Button
            variant="info"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              //   handleEdit(row);
              setItemToDelete(row); // Set the item to delete
              setShowEditCanvas(true);
            }}
          >
            Edit
          </Button>{" "}
          <Button
            variant="danger"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              setItemToDelete(row); // Set the item to delete
              setShowModal(true); // Show the modal
            }}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      {error && (
        <Alert
          variant="danger"
          onClick={() => setSuccess(false)}
          dismissible
          transition={true}
        >
          {error}
        </Alert>
      )}
      {success && (
        <Alert
          variant="success"
          onClick={() => setSuccess(false)}
          dismissible
          transition={true}
        >
          {successmsg}
        </Alert>
      )}
      <Row style={{ width: "50%" }}>
        <Col xs={2}>
          <Button
            variant="outline-primary"
            onClick={toggleFilter}
            style={{ marginBottom: "10px" }}
          >
            Filter 
          </Button>
        </Col>
        {/* <Col xs={4}>
          <Button variant="outline-info" onClick={toggleCamera}>
            <FontAwesomeIcon icon={faQrcode} /> Scan
          </Button>
        </Col> */}
      </Row>
      {openCamera && (
        <QRScannerModal
          show={openCamera}
          handleClose={toggleCamera}
          getData={handleGetSelectedOptions}
        />
      )}
          <BootstrapTable
            keyField="id"
            data={inventoryData}
            columns={columns}
            pagination={paginationFactory()}
            filter={filterFactory()}
            striped
            hover
            bootstrap4
            bordered={true}
            rowEvents={{
              onClick: (e, row) => {
                handleRowClick(row);
              },
            }}
          />

      <Offcanvas
        show={showOffcanvas}
        onHide={closeOffcanvas}
        style={{ width: "100%" }}
      >
        {error && (
          <Alert
            variant="danger"
            onClick={() => setSuccess(false)}
            dismissible
            transition={true}
          >
            {error}
          </Alert>
        )}
        {success && (
          <Alert
            variant="success"
            onClick={() => setSuccess(false)}
            dismissible
            transition={true}
          >
            {successmsg}
          </Alert>
        )}
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Button variant="outline-primary" disabled>
              TRANSACTIONS
            </Button>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col md={6}>
              {transactionData.length > 0 ? (
                <div style={{ height: "800px", overflowY: "scroll" }}>
                  <TimelineComponent data={transactionData} />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                >
                  No Transactions
                </div>
              )}
            </Col>
            <Col md={6}>
              <div style={{ fontWeight: "bold" }}>Perform Transaction</div>
              <Row>
                <Col>
                  <Button variant="outline-success">
                    Initial Amount{" "}
                    <Badge bg="secondary">{selectedRow?.seed_amount}</Badge>
                    {/* <span className="visually-hidden">Initial Amount</span> */}
                  </Button>
                </Col>
              </Row>
              <Container style={{ marginTop: "12px" }}>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="transaction_type">
                    <Form.Label>Transaction Type</Form.Label>
                    <Select
                      name="transaction_type"
                      value={options.find(
                        (option) => option.value === formData.transaction_type
                      )}
                      options={options}
                      onChange={handleTypeChange}
                      aria-label="Select transaction type"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="amount_changed">
                    <Form.Label>Amount Changed</Form.Label>
                    <Form.Control
                      type="number"
                      name="amount_changed"
                      value={formData.amount_changed}
                      onChange={handleChange}
                      placeholder="Enter amount changed"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="notes">
                    <Form.Label>Notes</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="notes"
                      value={formData.notes}
                      onChange={handleChange}
                      placeholder="Enter notes"
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </Form>
              </Container>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>

      <ConfirmationModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={handleDeleteConfirm}
        itemName={itemToDelete ? itemToDelete.item_name : ""}
      />
      {itemToDelete !== null && showEditCanvas && (
        <UpdateInventory
          data={itemToDelete}
          show={showOffcanvas}
          handleClose={closeEditcanvas}
          fetchData={fetchData}
        />
      )}
      {filteresData !== null && (
        <Offcanvas show={showFilter} onHide={toggleFilter}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Filter Inventory</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ overflow: "inherit" }}>
            <Row>
              <Col xs={12} style={{ marginBottom: "7px" }}>
                {/* Select for Tasks */}
                <Select
                  options={filteresData.Task.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Tasks"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, task_id: selectedOptions })
                  }
                  value={params.task_id || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Crop */}
                <Select
                  options={filteresData.Crop.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Crop"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, crop: selectedOptions })
                  }
                  value={params.crop || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Farm */}
                <Select
                  options={filteresData.Farm.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Farm"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, farm: selectedOptions })
                  }
                  value={params.farm || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for CSI */}
                <Select
                  options={filteresData.CSI.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="CSI"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, csi: selectedOptions })
                  }
                  value={params.csi || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Trial */}
                <Select
                  options={filteresData.Trial.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Trial"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, trial: selectedOptions })
                  }
                  value={params.trial || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Crop */}
                <Select
                  options={filteresData.Trial_Type.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Trial Type"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, trial_type: selectedOptions })
                  }
                  value={params.trial_type || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Year */}
                <Select
                  options={filteresData.Year.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Year"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, year: selectedOptions })
                  }
                  value={params.year || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Year */}
                <Select
                  options={filteresData.Location.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Location"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, location: selectedOptions })
                  }
                  value={params.location || null}
                  isClearable={true}
                />
              </Col>
            </Row>
            <Row>
              <Stack
                direction="horizontal"
                gap={3}
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="secondary"
                  onClick={async () => {
                    setSubmitLoading(true); // Set loading state to true when submitting
                    await handleGetSelectedOptions(); // Call submit function
                    setSubmitLoading(false); // Set loading state to false when done
                  }}
                  disabled={submitLoading} // Disable button when loading
                >
                  {submitLoading ? "Fetching Data..." : "Fetch"}
                </Button>
                <div className="vr" />
                <Button variant="outline-danger" onClick={toggleFilter}>
                  Cancel
                </Button>
              </Stack>
            </Row>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

export default Transactions;
