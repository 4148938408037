import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { useAuth } from "../Services/AuthContext"; // Adjust the import path as needed

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { authState } = useAuth();
  const isAuthenticated = authState.authenticated;

  return (
    <Routes>
      <Route
        {...rest}
        element={isAuthenticated ? <Component /> : <Navigate to="/login" />}
      />
    </Routes>
  );
};

export default ProtectedRoute;
