import React, { createContext, useContext } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { useCookies } from "react-cookie";

const AxiosContext = createContext();
const { Provider } = AxiosContext;

const AxiosProvider = ({ children }) => {
  const authContext = useContext(AuthContext);

  let baseURL = "";

  if (window.location.origin === "http://localhost:8009") {
    baseURL = "http://0.0.0.0:8008/";
    // baseURL = "https://sixty-cups-see.loca.lt/";
  } else {
    baseURL = `${window.location.origin}/`;
  }

  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.xsrfHeaderName = "X-CSRFToken";
  const authAxios = axios.create({
    baseURL,
    withCredentials: true,
  });

  const publicAxios = axios.create({
    baseURL,
    withCredentials: true,
  });

  authAxios.interceptors.request.use(
    (config) => {
      if (!config.headers.Authorization) {
        config.headers[
          "Authorization"
        ] = `Bearer ${authContext.getAccessToken()}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const refreshAuthLogic = (failedRequest) => {
    const data = {
      refresh: authContext.authState.refreshToken,
    };

    const options = {
      method: "POST",
      data,
      url: `https://www.caessia.com/api/token/refresh/`,
    };

    return axios(options)
      .then(async (tokenRefreshResponse) => {
        failedRequest.response.config.headers.Authorization =
          "Bearer " + tokenRefreshResponse.data.access;

        authContext.setAuthState({
          ...authContext.authState,
          accessToken: tokenRefreshResponse.data.access,
          refreshToken: authContext.authState.refresh,
        });

        await localStorage.setItem(
          "token",
          JSON.stringify({
            accessToken: tokenRefreshResponse.data.accessToken,
            refreshToken: authContext.authState.refreshToken,
          })
        );

        return Promise.resolve();
      })
      .catch((e) => {
        authContext.setAuthState({
          accessToken: null,
          refreshToken: null,
        });
      });
  };

  createAuthRefreshInterceptor(authAxios, refreshAuthLogic, {});

  return (
    <Provider
      value={{
        authAxios,
        publicAxios,
      }}
    >
      {children}
    </Provider>
  );
};

export { AxiosContext, AxiosProvider };
