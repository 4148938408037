import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBriefcase,
  faImage,
  faTimes,
  faGear,
  faSackDollar,
  faInfo,
  faTableColumns,
} from "@fortawesome/free-solid-svg-icons";
import { Nav, Button, Form, Row, Col } from "react-bootstrap";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { faSignOut } from "@fortawesome/pro-light-svg-icons";
import { faGrid2, faShelves } from "@fortawesome/pro-regular-svg-icons";
import logo from "../assets/lims.png";
import { AuthContext } from "../Services/AuthContext";
import {
  faAnalytics,
  faCabinetFiling,
  faDiagramProject,
  faInventory,
} from "@fortawesome/pro-solid-svg-icons";

function SideBar(props) {
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);

  const handleLogout = () => {
    // Call the logout function from AuthContext
    authContext.logout();
  };

  return (
    <>
      <div className={classNames("sidebar", { "is-open": props.isOpen })}>
        <div className="sidebar-header">
          <Button
            variant="link"
            onClick={props.toggle}
            style={{ color: "#fff" }}
            className="mt-4"
          >
            <FontAwesomeIcon icon={faTimes} pull="right" size="xs" />
          </Button>
          <Link
            to="/dashboard"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Row>
              <Col md={4}>
                <img src={logo} width="60px" alt="Image" />
              </Col>
              <Col md={4}>
                <h3>LIMS NEXUS</h3>
              </Col>
            </Row>
          </Link>
        </div>
        <hr style={{ margin: "5px" }} />

        <Nav className="flex-column pt-2">
          <Nav.Item>
            <Link
              to="/dashboard"
              className={classNames("nav-link", {
                active: location.pathname === "/dashboard",
              })}
            >
              <FontAwesomeIcon icon={faHome} className="mr-2" />
              <span>Dashboard</span>
            </Link>
          </Nav.Item>

          {/* <Nav.Item>
            <Link
              to="/reports"
              className={classNames("nav-link", {
                active: location.pathname === "/reports",
              })}
            >
              <FontAwesomeIcon icon={faCabinetFiling} className="mr-2" />
              Records
            </Link>
          </Nav.Item> */}
          {/* <Nav.Item>
            <Link
              to="/images"
              className={classNames("nav-link", {
                active: location.pathname === "/images",
              })}
            >
              <FontAwesomeIcon icon={faImage} className="mr-2" />
              Images
            </Link>
          </Nav.Item> */}
          {/* <Nav.Item>
            <Link
              to="/projects"
              className={classNames("nav-link", {
                active: location.pathname === "/projects",
              })}
            >
              <FontAwesomeIcon icon={faDiagramProject}  className="mr-2" />
              Projects
            </Link>
          </Nav.Item> */}

          <Nav.Item>
            <Link
              to="/stats"
              className={classNames("nav-link", {
                active: location.pathname === "/stats",
              })}
            >
              <FontAwesomeIcon icon={faAnalytics} size="lg" className="mr-2" />{" "}
              Stats
            </Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Link
              to="/inventory"
              className={classNames("nav-link", {
                active: location.pathname === "/inventory",
              })}
            >
              <FontAwesomeIcon icon={faInventory} size="lg" className="mr-2" />{" "}
              Inventory
            </Link>
          </Nav.Item> */}
          <Nav.Item>
            <Link
              to="/settings"
              className={classNames("nav-link", {
                active: location.pathname === "/settings",
              })}
            >
              <FontAwesomeIcon icon={faGear} size="lg" className="mr-2" />
              Settings
            </Link>
          </Nav.Item>
        </Nav>
        {/* <hr style={{ margin: "5px" }} /> */}
      </div>
    </>
  );
}

export default SideBar;
