import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Login from '../Login&Logout/Login';

const DashboardRoutes = lazy(() => import('./DashboardRoutes'));
const SettingsRoutes = lazy(() => import('./SettingsRoutes'));
const ProjectRoutes = lazy(() => import('./ProjectRoutes'));
const Data = lazy(() => import('../MainPages/Data'));
const About = lazy(() => import('../MainPages/About'));
const Images = lazy(() => import('../MainPages/Images'));
const Inventory = lazy(() => import('../MainPages/InventoryFolder/Inventory'));
const InventoryArchitecture = lazy(() => import('../MainPages/InventoryFolder/inventoryArchitecture'));

const AppRoutes = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Routes>
      <Route path="/*" element={<ProtectedRoute component={DashboardRoutes} />} />
      <Route path="/data" element={<ProtectedRoute component={Data} />} />
      <Route path="/reports" element={<ProtectedRoute component={About} />} />
      <Route path="/images" element={<ProtectedRoute component={Images} />} />
      <Route path="/inventory" element={<ProtectedRoute component={Inventory} />} />
      <Route path="/inventory/architecture" element={<ProtectedRoute component={InventoryArchitecture} />} />
      <Route path="/settings/*" element={<ProtectedRoute component={SettingsRoutes} />} />
      <Route path="/projects/*" element={<ProtectedRoute component={ProjectRoutes} />} />
      <Route path="/login" element={<Login />} />

      
    </Routes>
  </Suspense>
);

export default AppRoutes;
