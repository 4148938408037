import React from "react";
import { Line } from "react-chartjs-2";

const LineChart = ({ data }) => {
    data.sort((a, b) => new Date(a.truncated_date) - new Date(b.truncated_date));
  const chartData = {
    labels: data.map((entry) => formatDate(entry.truncated_date)),
    datasets: [
      {
        label: "Task Count",
        data: data.map((entry) => entry.task_count),
        fill: false,
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 2,
      },
    ],
  };
  const options = {
    maintainAspectRatio: true, // Set to false to allow resizing
    responsive: true, // Enable responsiveness
  };

  function formatDate(dateString) {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
    return formattedDate;
  }

  return (
    <div>
      <h5  className="d-flex justify-content-center align-items-center">Tasks Creation Over Time</h5>
      <Line data={chartData} options={options}/>
    </div>
  );
};

export default LineChart;
