import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DataTable from "../../Components /DataTable";
import CustomModal from "../../Components /CustomModal";
import apiCalls from "../../Services/AxiosCalls";
import { useFlashMessage } from "../../Components /FlashMessageContext";

const Formulas = () => {
  const navigate = useNavigate();
  const { showFlashMessage } = useFlashMessage();

  const [blocks, setBlocks] = useState(null);
  const [stations, setStations] = useState(null);
  const [formulas, setFormulas] = useState(null);
  const [units, setUnits] = useState(null);

  const [ports, setPorts] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [updatedName, setUpdatedName] = useState("");
  const [updateStation, setUpdatedStation] = useState(null);
  const [updatedDescription, setUpdatedDescription] = useState("");
  const [viewPorts, setViewPorts] = useState(false);
  const [blocID, setBlockID] = useState(null);
  const [formValue, setFormValue] = useState({
    name: "",
    function: "",
    unit: "",
    block: null,
  });

  useEffect(() => {
    getBlocks();
    getStations();
    getUnits();
  }, []);

  const getBlocks = async () => {
    try {
      const formulas = await apiCalls.getAllFormulas();
      console.log(formulas.data);
      const blocks = await apiCalls.getAllMachines();
      setBlocks(blocks.data);
      setFormulas(formulas.data);
    } catch (error) {
      console.error("Error fetching blocks and formulas:", error);
      showFlashMessage("Error fetching blocks and formulas", "danger");
    }
  };

  const getStations = async () => {
    try {
      const response = await apiCalls.getAllStations();
      setStations(response.data);
    } catch (error) {
      console.error("Error fetching stations:", error);
      showFlashMessage("Error fetching stations", "danger");
    }
  };
  const getUnits = async () => {
    try {
      const response = await apiCalls.getCommonUnit();
      console.log(response.data);

      setUnits(response.data);
    } catch (error) {
      console.error("Error fetching stations:", error);
      showFlashMessage("Error fetching stations", "danger");
    }
  };

  const handleRowClick = (rowData) => {
    setFormValue({
      ...formValue,
      name: rowData.name,
      function: rowData.function,
      unit: rowData.unit,
      id: rowData.id,
    });
    console.log(rowData);

    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getPorts = async (data) => {
    const response = await apiCalls.getblockPorts(data);
    setPorts(response.data);
    setFormValue({
      ...formValue,
      block: data,
    });
  };

  const handleCreateCloseModal = () => {
    setCreateModal(false);
  };

  const handlePortCloseModal = () => {
    setViewPorts(false);
  };

  const handleUpdateName = async () => {
    try {
      const updatedData = {
        id: formValue.id,
        name: formValue.name,
        function: formValue.function,
        unit: formValue.unit,
      };
      console.log(updatedData);
      const response = await apiCalls.updateFormula(
        updatedData.id,
        updatedData
      );

      if (response.status === 200) {
        showFlashMessage("Formula Updated!", "success");
        setShowModal(false);
        getBlocks();
      } else {
        console.error("Unexpected API response:", response);
        showFlashMessage("Error updating Formula", "danger");
      }
    } catch (error) {
      console.error("Error updating Formula:", error);
      showFlashMessage("Error updating Formula", "danger");
    }
  };

  const handleAddStation = () => {
    setFormValue({
      ...formValue,
      name: '',
      function: '',
      unit: '',
     
    });
    setCreateModal(true);
  };

  const handlePortModal = () => {
    setViewPorts(true);
  };

  const handleSaveStation = async () => {
    try {
      console.log(formValue);
      const response = await apiCalls.saveFormula(formValue);
      if (response.status === 201) {
        showFlashMessage("Formula Created!", "success");
        setCreateModal(false);
        getBlocks();
      } else {
        console.error("Unexpected API response:", response);
        showFlashMessage("Error creating Formula", "danger");
      }
    } catch (error) {
      console.error("Error creating block:", error);
      showFlashMessage("Error creating Formula ", "danger");
    }
  };

  const handleButtonClick = (button) => {
    const selectedButton = button.name;
    const updatedDescription = formValue.function
      ? `${formValue.function}<${selectedButton}>`
      : selectedButton;

    setFormValue({
      ...formValue,
      function: updatedDescription,
      //   [selectedButton]: true, // Track the selected button
    });
  };

  const headers = ["name", "unit"];

  return (
    <>
      <div className="d-flex justify-content-end mb-3 ">
        <Button
          variant="success"
          onClick={handleAddStation}
          style={{ marginLeft: 12 }}
        >
          Create New
        </Button>
      </div>
      {formulas && (
        <DataTable
          headers={headers}
          data={formulas}
          onRowClick={handleRowClick}
        />
      )}
      <CustomModal
        show={showModal}
        onHide={handleCloseModal}
        title="Edit Formula"
        content={
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Formula name"
                value={formValue.name}
                onChange={(e) =>
                  setFormValue({ ...formValue, name: e.target.value })
                }
              />
            </Form.Group>
            {formulas && (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
                

              >
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => getPorts(e.target.value)}
                  placeholder="Select Block"
                  
                >
                  <option value="">Select Block</option>
                  {blocks.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}

            {ports && (
              <div className="d-flex flex-wrap">
                {ports.map((button) => (
                  <Button
                    key={button.id}
                    variant="primary"
                    className={`m-2 ${
                      formValue[button.name] ? "selected" : ""
                    }`}
                    onClick={() => handleButtonClick(button)}
                  >
                    {button.name}
                  </Button>
                ))}
              </div>
            )}
            {units && (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
                

              >
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) =>
                    setFormValue({ ...formValue, unit: e.target.value })
                  }
                  placeholder="Select Unit"
                >
                  <option value="">Select Unit</option>
                  {units.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Define formula. eg: sum=<port1>+<port2>+<100>"
                value={formValue.function}
                onChange={(e) =>
                  setFormValue({ ...formValue, function: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        }
        btnName="Update"
        onSave={handleUpdateName}
      />
      <CustomModal
        show={createModal}
        onHide={handleCreateCloseModal}
        title="Create Formula"
        content={
          <Form noValidate validated="false">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Formula name"
                value={formValue.name}
                onChange={(e) =>
                  setFormValue({ ...formValue, name: e.target.value })
                }
                required
              />
            </Form.Group>
            {formulas && (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => getPorts(e.target.value)}
                  placeholder="Select Block"
                  required

                >
                  <option value="">Select Block</option>
                  {blocks.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}

            {ports && (
              <div className="d-flex flex-wrap">
                {ports.map((button) => (
                  <Button
                    key={button.id}
                    variant="primary"
                    className={`m-2 ${
                      formValue[button.name] ? "selected" : ""
                    }`}
                    onClick={() => handleButtonClick(button)}
                  >
                    {button.name}
                  </Button>
                ))}
              </div>
            )}
            {units && (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) =>
                    setFormValue({ ...formValue, unit: e.target.value })
                  }
                  required
                  placeholder="Select Unit"
                >
                  <option value="">Select Unit</option>
                  {units.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Define formula. eg: sum=<port1>+<port2>+<100>"
                value={formValue.function}
                onChange={(e) =>
                  setFormValue({ ...formValue, function: e.target.value })
                }
                required

              />
            </Form.Group>
          </Form>
        }
        btnName="Save"
        onSave={handleSaveStation}
      />
    </>
  );
};

export default Formulas;
