import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';

const QuadrantLayout = ({ children }) => {
  return (
    <Row className="quadrant-layout" style={{ margin: '0 10px' }}>
      {children.map((child, index) => (
        <Col
          key={index}
          xs={12}    // Full width on extra small screens
          sm={5}     // Half width on small screens
          md={5}     // Half width on medium screens
          lg={5}     // Half width on large screens
          xl={5}     // Half width on extra large screens
          className="quadrant"
          style={{ marginBottom: '16px' }} // Add some spacing between columns
        >
          <Card 
            style={{
              padding: '20px',
              backgroundColor: '#f8f9fa', // Light grey background
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
              height: '100%', // Ensures the card fills the entire column
            }}
          >
            {child}
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default QuadrantLayout;
