import React, { useEffect, useState } from "react";
import { Form, Button, Stack, Alert, Row, Col } from "react-bootstrap";
import apiCalls from "../../Services/AxiosCalls";
import { useFlashMessage } from "../../Components /FlashMessageContext";
import DataTable from "../../Components /DataTable";
import EditModal from "./EditModal";
import ExportCSV from "./ExportCSV";

const FacilityForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    location: "",
    department: 0,
  });
  const { showFlashMessage } = useFlashMessage();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [facilities, setFacilities] = useState([]); // State variable to toggle between form and DataTable
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedRow, setSelectedRowId] = useState(null);
  const [downloadData, setDownloadData] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const fetchData = async () => {
    try {
      const storageFacilityData = await apiCalls.getStorageFacility();
      setFacilities(storageFacilityData.data);
      const items = {
        items: storageFacilityData?.data.map((data) => ({
          title: data.name,
          data: JSON.stringify({
            key: "storage_facility",
            id: data.id,
            name: data.name,
          }).replace(/"/g, '""'), // Properly escape quotes
          description: data.description,
        })),
      };
      setDownloadData(items);
    } catch (error) {
      console.error("Error fetching storage facility data:", error);
      setError(error.response.message);
    }
  };
  useEffect(() => {
    fetchData(); // Call the async function inside useEffect
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);

    try {
      // Submit the form data
      const response = await apiCalls.postStorageFacility(formData);
      console.log("Form submitted:", response.data);
      setSuccess(true);
      fetchData();
    } catch (error) {
      console.error("Error submitting form:", error);
      setError(error.message);
    }
  };

  const handleToggle = () => {
    setShowForm((prevShowForm) => !prevShowForm); // Toggle between showing form and DataTable
  };
  const headers = ["name", "location", "description"];

  const handleRowClick = (rowData) => {
    console.log(rowData);
    setOpenEdit(true);
    setSelectedRowId(rowData);
  };

  const toggleEditModal = () => {
    fetchData();
    setOpenEdit((prevOpenEdit) => !prevOpenEdit);
  };

  const postQRCode = async () => {
    try {
      // Map facilities data directly and create items object
      const items = {
        items: await Promise.all(
          facilities.map(async (data) => ({
            title: data.name,
            data: { key: "storage_facility", id: data.id, name: data.name },
          }))
        ),
      };

      console.log(items);

      // Call the API to generate the QR PDF with items directly
      const pdfData = await apiCalls.generateQRPDF(items);
      if (pdfData && pdfData.data) {
        console.log(pdfData);

        // Convert the PDF data to a Blob
        const pdfBlob = new Blob([pdfData.data], { type: "application/pdf" });
        console.log(pdfBlob);

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(pdfBlob);

        // Create a link element
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `storage.pdf`);

        // Append the link to the document body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Cleanup: remove the link and revoke the URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Invalid PDF data received");
      }
    } catch (error) {
      console.error("Error while generating QR PDF:", error);
    }
  };

  return (
    <>
      <Row className="justify-content-between">
        <Col md={4}>
          <h5>Storage Facility</h5>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            variant="link"
            onClick={handleToggle}
            className="d-flex justify-content-end"
          >
            {showForm ? "EDIT FACILITY" : "CREATE FACILITY"}{" "}
          </Button>
        </Col>
      </Row>
      {showForm ? ( // Conditionally render form or DataTable
        <>
          {error && <Alert variant="danger">{error}</Alert>}
          {success && (
            <Alert variant="success">Facility saved successfully!</Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter facility name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formLocation">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter description"
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </Form.Group>

            <Stack direction="horizontal" gap={3}>
              <Button variant="primary" type="submit">
                Submit
              </Button>
              {/* <Button variant="outline-danger">Cancel</Button> */}
            </Stack>
          </Form>
        </>
      ) : (
        <>
         
          <ExportCSV data={downloadData} />

          <DataTable
            headers={headers}
            data={facilities}
            onRowClick={handleRowClick}
          />
        </>
      )}

      {openEdit && (
        <EditModal
          show={openEdit}
          onHide={toggleEditModal}
          resourceType={"Facility"}
          resourceData={selectedRow}
          title={selectedRow.name}
          //   fetchDatabase={fetchData}
        />
      )}
    </>
  );
};

export default FacilityForm;
