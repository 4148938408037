import React, { useEffect, useState } from "react";
import apiCalls from "../Services/AxiosCalls";
import ClickableTable from "../Components /ClickableTable";
import Pagination from "../Components /Pagination";
import { Button, Col, Offcanvas, Row, Stack } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { helix, jelly, square } from "ldrs";
import MainTable from "../Components /MainTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

helix.register();
jelly.register();
square.register();

const About = () => {
  const [dataArray, setDataArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showNext, setShowNext] = useState(false);
  const [showPrev, setShowPrev] = useState(false);
  const [error, setError] = useState(null);
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [qParams, setQParams] = useState({});
  const [totalPages, setTotalPages] = useState(null);
  const [params, setParams] = useState({});
  const [filteresData, setFilteresData] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const fetchOptions = async (data) => {
    try {
      setCurrentPage(1);

      try {
        const response = await apiCalls.ExtractFilteres();
        console.log(response.data);
        setFilteresData(response.data);
        // setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };
  const fetchData = async (page) => {
    try {
      setLoading(true);
      const BL_params = {
        project_id: params.project_id?.value,
        crop: params.crop?.value,
        csi: params.csi?.value,
        farm: params.farm?.value,
        trial: params.trial?.value,
        year: params.year?.value,
        location: params.location?.value,
        task_id: params.task_id?.value,
        // page: page,
      };
      console.log(BL_params);


      const response = await apiCalls.getAllRecordHistory(page, BL_params);
      console.log(response);
      // setDataArray(response.data\););

      const restructureData = () => {
        // Check if the count is 0
        const filteredResults = response.data.results.filter((item) => {
          return !(item.error === "'NoneType' object has no attribute 'get'");
        });

        if (response.data.count === 0) {
          console.log("response");

          setDataArray([]);
          return;
        }

        const result = {};

        // Iterate through the input data
        filteredResults.forEach((item) => {
          const {
            iteration,
            stations: station,
            block,
            record_data: recordData,
            item: Item,
            task,
            task_name,
            data_dict,
            images,
            updated_at,
          } = item;

          // Check if the iteration exists in the result
          if (!result[iteration]) {
            result[iteration] = {
              Item,
              item_id: task,
              task_name,
              data_dict,
              data: [],
              images,
            };
          }

          // Check if the station already exists for this iteration
          const existingStation = result[iteration].data.find(
            (entry) => entry.station === station
          );

          // Format the Time (updated_at) to a readable string
          const formattedTime = new Date(
            recordData.updated_at
          ).toLocaleString();

          // If the station doesn't exist, create a new entry
          if (!existingStation) {
            result[iteration].data.push({
              station,
              block,
              records: [recordData],
              user: recordData.user,
              Time: formattedTime,
            });
          } else {
            // If the station exists, append the record data if it's not a duplicate
            if (!existingStation.records.includes(recordData)) {
              existingStation.records.push(recordData);
            }
          }
        });

        // Convert the result object to an array for the final restructured data
        const dataArray = Object.values(result);
        console.log(dataArray);
        setDataArray(dataArray);
        return dataArray;
      };

      restructureData(); // Call the function to handle the data

      setTotalPages(response.data.num_pages);
      setShowNext(response.data.next);
      setShowPrev(response.data.previous);
      setError(null);
    } catch (error) {
      setError("Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOptions();
    console.log(currentPage, qParams);
    fetchData(currentPage, qParams);
  }, []);

  useEffect(() => {
    // Initialize selectedValues based on initially selected filters
    const initialSelectedValues = selectedFilters.map(() => []);
    setSelectedValues(initialSelectedValues);
  }, [selectedFilters]);

 





  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchData(newPage, qParams);
  };
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <>
      <div>
        <Row style={{ marginBottom: 5 }}>
          <Col>
            {/* Button to toggle the filter offcanvas */}
            <Button
              style={{ backgroundColor: "#4E73DF" }}
              onClick={toggleFilter}
            >
              <FontAwesomeIcon icon={faFilter} /> Filters
            </Button>
          </Col>
        </Row>
      </div>

      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "90vh" }}
        >
          <l-square size="60" speed="0.9" color="#354052"></l-square>
        </div>
      ) : dataArray.length === 0 ? (
        <div className="text-center mt-4">NO DATA</div>
      ) : (
        <ClickableTable data={dataArray} />
        // <MainTable data={dataArray} />
      )}
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        canGoToPreviousPage={() => handlePageChange(currentPage - 1)}
        canGoToNextPage={() => handlePageChange(currentPage + 1)}
        canShowNext={showNext}
        canShowPrevious={showPrev}
      />
      {error && <p style={{ color: "red" }}>{error}</p>}
      {filteresData !== null && (
        <Offcanvas show={showFilter} onHide={toggleFilter}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Filter Records</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ overflow: "inherit" }}>
            <Row>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Project */}
                <Select
                  options={filteresData.Project.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Project"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, project_id: selectedOptions })
                  }
                  value={params.project_id || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "7px" }}>
                {/* Select for Tasks */}
                <Select
                  options={filteresData.Task.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Tasks"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, task_id: selectedOptions })
                  }
                  value={params.task_id || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Crop */}
                <Select
                  options={filteresData.Crop.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Crop"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, crop: selectedOptions })
                  }
                  value={params.crop || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Farm */}
                <Select
                  options={filteresData.Farm.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Farm"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, farm: selectedOptions })
                  }
                  value={params.farm || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for CSI */}
                <Select
                  options={filteresData.CSI.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="CSI"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, csi: selectedOptions })
                  }
                  value={params.csi || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Trial */}
                <Select
                  options={filteresData.Trial.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Trial"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, trial: selectedOptions })
                  }
                  value={params.trial || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Year */}
                <Select
                  options={filteresData.Year.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Year"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, year: selectedOptions })
                  }
                  value={params.year || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Year */}
                <Select
                  options={filteresData.Location.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Location"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, location: selectedOptions })
                  }
                  value={params.location || null}
                  isClearable={true}
                />
              </Col>
            </Row>
            <Row>
              <Stack
                direction="horizontal"
                gap={3}
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="secondary"
                  onClick={async () => {
                    setSubmitLoading(true); // Set loading state to true when submitting
                    await fetchData(currentPage); // Call submit function
                    setSubmitLoading(false);
                    toggleFilter(); // Set loading state to false when done
                  }}
                  disabled={submitLoading} // Disable button when loading
                >
                  {submitLoading ? "Fetching Data..." : "Fetch"}
                </Button>
                <div className="vr" />
                <Button variant="outline-danger" onClick={toggleFilter}>
                  Cancel
                </Button>
              </Stack>
            </Row>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

export default About;
