import React from "react";
import { Chrono } from "react-chrono";

const TimelineComponent = ({ data }) => {
    const items = data.map((item) => ({
        title: item.transaction_type,
        cardTitle: `Amount ${item.transaction_type.toLowerCase()}d by ${item.amount_changed}`,
        cardSubtitle: `Transaction Time: ${new Date(
          item.transaction_time
        ).toLocaleString()}`,
        cardDetailedText: `Notes: ${item.notes}`,
        // date: new Date(item.transaction_time).toLocaleDateString(),
        // // Set color based on transaction type
        // cardColor: item.transaction_type === "INCREASE" ? "green" : "red",
      }));
      

  return (
    <Chrono
      items={items}
      mode="VERTICAL"
    //   style={{ width: "50%" }}
      
    />
  );
};

export default TimelineComponent;
