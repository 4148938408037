import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft, faUser } from "@fortawesome/free-solid-svg-icons";
import {
  Navbar,
  Button,
  Nav,
  Dropdown,
  Container,
  Form,
} from "react-bootstrap";
import { AuthContext } from "../Services/AuthContext";
import CustomModal from "../Components /CustomModal";
import apiCalls from "../Services/AxiosCalls";

const NavBar = ({ toggle }) => {
  const { user } = useContext(AuthContext);
  const authContext = useContext(AuthContext);

  const [showModal, setShowModal] = useState(false);
  const [localUser, setLocalUser] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [virtualModeActive, setVirtualModeActive] = useState(false);
  const [userEmail, setUser] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [expandedOrgId, setExpandedOrgId] = useState(null);

  const handleLogout = () => {
    authContext.logout();
  };

  useEffect(() => {
    // Retrieve and parse the stored user name from localStorage
    const storedUser = JSON.parse(localStorage.getItem("user_name"));
    setLocalUser(storedUser);
  
    // Check if the token has expired by checking the "tokenExpired" flag in localStorage
    const tokenExpired = localStorage.getItem("tokenExpired") === "true";
  
    // If the token has expired, trigger the logout process
    if (tokenExpired) {
      handleLogout();
    }
  }, [localStorage.getItem("tokenExpired")]);
  

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await apiCalls.virtualModeDepartments();
        console.log(response);
        const organizationsData = response.data.organizations;
        setUser(response.data?.user);

        if (response.data.user.virtual_mode === true) {
          setVirtualModeActive(true);
        }

        // Transform departments data to include organization info
        const transformedData = organizationsData.map((org) => ({
          ...org.organization,
          departments: org.departments,
        }));

        setDepartments(transformedData);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartments();
  }, []);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleVirtualModeSelect = async (departmentId) => {
    try {
      let data;

      if (departmentId === userEmail.original_department) {
        data = {
          activate: false,
          department_id: departmentId,
        };
      } else {
        data = {
          activate: true,
          department_id: departmentId,
        };
      }

      console.log("Sending data:", data);

      const response = await apiCalls.virtualModeActivate(data);

      window.location.href = "/dashboard";
      console.log("Response:", response.data);
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleDropdownToggle = (isOpen) => {
    setDropdownOpen(isOpen);
  };

  const handleDropdownDepartmentToggle = (orgId) => {
    setExpandedOrgId((prevOrgId) => (prevOrgId === orgId ? null : orgId));
  };

  const getDepartmentName = (departmentId) => {
    console.log(departments);
    for (const org of departments) {
      const department = org.departments.find((dep) => dep.id === departmentId);
      if (department) return department.name;
    }
    return "Virtual Mode";
  };

  return (
    <>
      <Navbar
        bg="light"
        className="navbar shadow-sm p-1 mb-2 bg-white rounded"
        expand="lg"
      >
        <Button variant="outline-info" onClick={toggle}>
          <FontAwesomeIcon icon={faAlignLeft} />
        </Button>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav className="ml-auto" classname="justify-content-end">
            {departments?.length > 0 && (
              <Dropdown
                className="d-inline-block ml-3"
                autoClose={false}
                drop="down-centered"
              >
                <Dropdown.Toggle variant="link" id="dropdown-virtual-mode">
                  {getDepartmentName(
                    userEmail?.virtual_department ??
                      userEmail?.original_department
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu align="end">
                  {departments.map((org) => (
                    <React.Fragment key={org.id}>
                      <Dropdown.Item
                        onClick={() => handleDropdownDepartmentToggle(org.id)}
                        eventKey={org.id}
                      >
                        {org.name}
                      </Dropdown.Item>
                      {expandedOrgId === org.id && (
                        <Dropdown.Menu flip="true">
                          {org.departments.map((department) => (
                            <Dropdown.Item
                              key={department.id}
                              onClick={() =>
                                handleVirtualModeSelect(department.id)
                              }
                            >
                              {department.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      )}
                    </React.Fragment>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}

            <Dropdown
              className="d-inline-block"
              show={dropdownOpen}
              onToggle={handleDropdownToggle}
              autoClose="outside"
            >
              <Dropdown.Toggle variant="link" id="dropdown-user">
                <FontAwesomeIcon icon={faUser} color="gray" />
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                <Dropdown.Item>{userEmail.email}</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <CustomModal
        show={showModal}
        onHide={handleCloseModal}
        title="Sample Modal"
        content="This is the modal content."
      />
    </>
  );
};

export default NavBar;
