import React, { useEffect, useState } from "react";
import { Row, Col, Button, Offcanvas, Stack, Spinner } from "react-bootstrap";
import Select from "react-select";
import apiCalls from "../Services/AxiosCalls";
import StatsTable from "../Components /StatsTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { Store } from 'react-notifications-component';
import flashMsg from "../Components /Notification";
import NotificationContent from "../Components /NotificationContent";


const Stats = () => {
  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState(null);
  const [filteresData, setFilteresData] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [params, setParams] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [badImageRecording, setBadImageRecording] = useState(null);

  // State to manage the visibility of the offcanvas

  useEffect(() => {
    getFilteredData();
    getExtractedFilteres();
  }, []);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };
  const getExtractedFilteres = async () => {
    try {
      const response = await apiCalls.ExtractFilteres();
      console.log(response.data);
      setFilteresData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const renderLoadingSpinner = () => {
    return (
      <div className="text-center my-3">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  };

  const getFilteredData = async () => {
    try {
      const response = await apiCalls.getAllMachinesStats();
      console.log(response.data);
      setData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleGetSelectedOptions = async () => {
    console.log(params);

    const BL_block_ids =
      params.block_id
        ?.filter((item) => item.type === "BL")
        .map((item) => item.value) || [];
    const IM_block_ids =
      params.block_id
        ?.filter((item) => item.type === "IM")
        .map((item) => item.value) || [];

    try {
      let combinedResults = [];

      if (BL_block_ids.length === 0 && IM_block_ids.length === 0) {
        const BL_params = {
          block_id: null,
          project_id: params.project_id?.value,
          crop: params.crop?.value,
          csi: params.csi?.value,
          farm: params.farm?.value,
          trial: params.trial?.value,
          year: params.year?.value,
          location: params.location?.value,
        };
        console.log(BL_params);
        const BL_response = await apiCalls.FilteredeReport(BL_params);
        console.log("BL Response:", BL_response.data);
        combinedResults = combinedResults.concat(BL_response.data);
        const IM_response = await apiCalls.FilteredImageReport(BL_params);
        console.log("IM Response:", IM_response.data);
        combinedResults = combinedResults.concat(IM_response.data);
      } else {
        if (BL_block_ids.length > 0) {
          const BL_params = {
            block_id: BL_block_ids?.join(",") || null,
            project_id: params.project_id?.value || null,
            crop: params.crop?.value || null,
            csi: params.csi?.value || null,
            farm: params.farm?.value || null,
            trial: params.trial?.value || null,
            year: params.year?.value || null,
            location: params.location?.value || null,
          };
          console.log(BL_params);
          const BL_response = await apiCalls.FilteredeReport(BL_params);
          console.log("BL Response:", BL_response.data);
          combinedResults = combinedResults.concat(BL_response.data);
        }
        if (IM_block_ids.length > 0) {
          const IM_params = {
            block_id: IM_block_ids?.join(",") || null,
            project_id: params.project_id?.value || null,
            crop: params.crop?.value || null,
            csi: params.csi?.value || null,
            farm: params.farm?.value || null,
            trial: params.trial?.value || null,
            year: params.year?.value || null,
            location: params.location?.value || null,
          };
          const IM_response = await apiCalls.FilteredImageReport(IM_params);
          // Assuming IM_response.data is an array of objects
          const objectsWithFalseAvgStats = IM_response.data.filter(
            (obj) => !obj.has_avg_stats_out
          );
          // if (objectsWithFalseAvgStats.length > 0) {
          //   flashMsg.imageError('These items should be re-imaged', <NotificationContent data={objectsWithFalseAvgStats}/>, 'warning');
          // }
          console.log(objectsWithFalseAvgStats)

          // Now objectsWithFalseAvgStats contains only the objects where has_avg_stats_out is false

          console.log("IM Response:", IM_response.data);
          combinedResults = combinedResults.concat(IM_response.data);
        }
      }

      console.log("Combined Results:", combinedResults);
      const transformedData = transformData(combinedResults);
      console.log(transformedData);

      if (transformedData.length === 0) {
        // Set special flag indicating no data found
        setTableData([{ noDataFound: true }]);
      } else {
        setTableData(transformedData);
      }
    } catch (error) {
      console.error("Error fetching selected options data:", error);
    } finally {
      toggleFilter();
    }
  };

  function transformData(inputData) {
    const groupedData = {};
    const entriesWithoutStations = [];

    inputData.forEach((entry) => {
      // console.log(inputData);
      // console.log(entry);

      const { stations, block } = entry;

      // Check if 'stations' is defined
      if (stations !== undefined) {
        const key = `${stations}-${block}`;
        if (!groupedData[key]) {
          groupedData[key] = { station: stations, block: block, data: [] };
        }
        const existingData = groupedData[key].data.find(
          (item) => item.task === entry.task
        );

        const record = entry.record_data;
        if (existingData) {
          existingData.records.push(record);
        } else {
          groupedData[key].data.push({
            task: entry.task,
            data_dict: entry.data_dict,
            task_name: entry.task_name,
            stations: entry.stations,
            block: entry.block,
            iteration: entry.iteration,
            item: entry.item,
            records: [record],
          });
        }
      } else {
        // If 'stations' is not defined, log the entry
        console.error("Entry without stations:", entry);
        entriesWithoutStations.push(entry);
      }
    });

    // Log entries without stations
    console.log("Entries without stations:", entriesWithoutStations);

    return Object.values(groupedData).map(({ station, block, data }) => [
      {
        station,
        block,
        data,
      },
    ]);
  }

  const handleSubmit = async () => {
    console.log(params);
  };

  // Usage example:

  return (
    <>
      <Row>
        <Col>
          {/* Button to toggle the filter offcanvas */}
          <Button style={{ backgroundColor: "#4E73DF" }} onClick={toggleFilter}>
            <FontAwesomeIcon icon={faFilter} /> Filters
          </Button>
        </Col>
      </Row>
      {tableData === null && (
        <div className="text-center my-3">SELECT FILTERS TO PULL DATA</div>
      )}

      {tableData !== null && <StatsTable data={tableData} />}
      {filteresData !== null && (
        <Offcanvas show={showFilter} onHide={toggleFilter}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Filters</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ overflow: "inherit" }}>
            <Row>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Block */}
                <Select
                  isMulti
                  options={filteresData.Block.map((item) => ({
                    value: item[0],
                    label: item[1],
                    type: item[2],
                  }))}
                  placeholder="Block"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, block_id: selectedOptions })
                  }
                  value={params.block_id || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Project */}
                <Select
                  options={filteresData.Project.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Project"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, project_id: selectedOptions })
                  }
                  value={params.project_id || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "7px" }}>
                {/* Select for Tasks */}
                <Select
                  options={filteresData.Task.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Tasks"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, task_id: selectedOptions })
                  }
                  value={params.task_id || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Crop */}
                <Select
                  options={filteresData.Crop.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Crop"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, crop: selectedOptions })
                  }
                  value={params.crop || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Farm */}
                <Select
                  options={filteresData.Farm.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Farm"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, farm: selectedOptions })
                  }
                  value={params.farm || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for CSI */}
                <Select
                  options={filteresData.CSI.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="CSI"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, csi: selectedOptions })
                  }
                  value={params.csi || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Trial */}
                <Select
                  options={filteresData.Trial.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Trial"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, trial: selectedOptions })
                  }
                  value={params.trial || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Year */}
                <Select
                  options={filteresData.Year.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Year"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, year: selectedOptions })
                  }
                  value={params.year || null}
                  isClearable={true}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: "5px" }}>
                {/* Select for Year */}
                <Select
                  options={filteresData.Location.map((item) => ({
                    value: item[0],
                    label: item[1],
                  }))}
                  placeholder="Location"
                  onChange={(selectedOptions) =>
                    setParams({ ...params, location: selectedOptions })
                  }
                  value={params.location || null}
                  isClearable={true}
                />
              </Col>
            </Row>
            <Row>
              <Stack
                direction="horizontal"
                gap={3}
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="secondary"
                  onClick={async () => {
                    setSubmitLoading(true); // Set loading state to true when submitting
                    await handleGetSelectedOptions(); // Call submit function
                    setSubmitLoading(false); // Set loading state to false when done
                  }}
                  disabled={submitLoading} // Disable button when loading
                >
                  {submitLoading ? "Fetching Data..." : "Fetch"}
                </Button>
                <div className="vr" />
                <Button variant="outline-danger" onClick={toggleFilter}>
                  Cancel
                </Button>
              </Stack>
            </Row>
          </Offcanvas.Body>
        </Offcanvas>
      )}
      {submitLoading && renderLoadingSpinner()}
    </>
  );
};

export default Stats;
