import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Select from "react-select";
import Chart from "chart.js/auto";
import { Colors } from "chart.js";

const GraphStats = ({ data, onDatasetClick }) => {
  const [xAxisData, setXAxisData] = useState([]);
  const [yAxisData, setYAxisData] = useState([]);
  const [graphType, setGraphType] = useState(null);
  const [chartInstance, setChartInstance] = useState(null);
  Chart.register(Colors);
  console.log(data);

  const handleDatasetClick = (label) => {
    onDatasetClick(label);
  };

  const handleXAxisSelect = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setXAxisData(selectedValues);
  };

  const handleYAxisSelect = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setYAxisData(selectedValues);
  };

  const handleGraphTypeSelect = (selectedOption) => {
    setGraphType(selectedOption.value);
  };

  const generateChart = () => {
    if (!xAxisData.length || !yAxisData.length || !graphType) {
      console.error("Please select X-axis data, Y-axis data, and graph type.");
      return;
    }

    const findObjectByValue = (data, value) => {
      return data.find((obj) => {
        // Check if any property of the object has the specified value
        return Object.values(obj).includes(value);
      });
    };

    // Destroy existing chart instance if it exists
    if (chartInstance) {
      chartInstance.destroy();
    }

    const ctx = document.getElementById("graphCanvas").getContext("2d");

    const datasets = yAxisData.map((yData) => ({
      label: yData,
      data: data.map((item) => item[yData]),
      backgroundColor: "rgba(75, 192, 192, 0.2)",
      borderColor: "rgba(75, 192, 192, 1)",
      borderWidth: 1,
    }));

    const newChartInstance = new Chart(ctx, {
      type: graphType,
      data: {
        labels: data.map((item) => item[xAxisData[0]]), // Assuming only one xAxis data is selected
        datasets: datasets,
      },
      options: {
        plugins: {
          colors: {
            enabled: true,
            forceOverride: true,
          },
        },
        onClick: (event, elements, chart) => {
          if (elements[0]) {
            const i = elements[0].index;
            console.log(chart.data.labels[i]);
            console.log(chart.data.datasets[0].data[i]);
            const results = findObjectByValue(data, chart.data.labels[i]);
            console.log(results);

            //    alert(chart.data.labels[i] + ': ' + chart.data.datasets[0].data[i]);
          }
        },
      },
    });

    setChartInstance(newChartInstance);
  };

  return (
    <>
      <Row>
        <Col xs={3}>
          <Select
            isMulti
            options={Object.keys(data[0]).map((key) => ({
              value: key,
              label: key,
            }))}
            onChange={handleXAxisSelect}
            placeholder="Select X-axis Data"
          />
        </Col>

        <Col xs={3}>
          <Select
            isMulti
            options={Object.keys(data[0]).map((key) => ({
              value: key,
              label: key,
            }))}
            onChange={handleYAxisSelect}
            placeholder="Select Y-axis Data"
          />
        </Col>
        <Col xs={3}>
          <Select
            options={[
              { value: "line", label: "Line" },
              { value: "bar", label: "Bar" },
              { value: "radar", label: "Radar" },
              { value: "scatter", label: "Scatter" },
              { value: "polarArea", label: "Polar Area" },
              { value: "bubble", label: "Bubble" },

              // Add more graph types as needed
            ]}
            onChange={handleGraphTypeSelect}
            placeholder="Select Graph Type"
          />
        </Col>
        <Col xs={3}>
          <Button
            onClick={generateChart}
            style={{
              backgroundColor: "#4E73DF",
            }}
          >
            Generate Chart
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <canvas id="graphCanvas"></canvas>
        </Col>
      </Row>
    </>
  );
};

export default GraphStats;
