import React, { useState } from "react";
import { Offcanvas, Button, Form, Stack } from "react-bootstrap";
import FacilityForm from "./FacilityForm"; // Import FacilityForm component
import RackForm from "./RackForm"; // Import RackForm component
import ShelfForm from "./ShelfForm"; // Import ShelfForm component
import BoxForm from "./BoxForm"; // Import BoxForm component

const InventoryArchitecture = ({ isOpen, onClose }) => {
  const [activeComponent, setActiveComponent] = useState('Facility'); // State to track active component

  // Function to handle button click and set active component
  const handleButtonClick = (component) => {
    setActiveComponent(component);
  };

  // Render active component based on activeComponent state
  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "Facility":
        return <FacilityForm />;
      case "Rack":
        return <RackForm />;
      case "Shelves":
        return <ShelfForm />;
      case "Boxes":
        return <BoxForm />;
      default:
        return null;
    }
  };

  return (
    <>
      <Stack direction="horizontal" gap={3}>
        <Button
          variant="outline-secondary"
          onClick={() => handleButtonClick("Facility")}
        >
          Facility
        </Button>
        <Button
          variant="outline-secondary"
          onClick={() => handleButtonClick("Rack")}
        >
          Rack
        </Button>
        <Button
          variant="outline-secondary"
          onClick={() => handleButtonClick("Shelves")}
        >
          Shelves
        </Button>
        <Button
          variant="outline-secondary"
          onClick={() => handleButtonClick("Boxes")}
        >
          Boxes
        </Button>
      </Stack>
      <hr />
      {renderActiveComponent()}
    </>
  );
};

export default InventoryArchitecture;
