import React, { useState } from "react";
import { Form, Button, Stack, Alert, Modal } from "react-bootstrap";
import apiCalls from "../../Services/AxiosCalls";
import { saveAs } from "file-saver";

const EditFacility = ({ data, onHide }) => {
  const [formData, setFormData] = useState({ ...data });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDelete = async () => {
    try {
      await apiCalls.deleteFacility(formData.id);
      //   setSuccess(true);
      setShowDeleteModal(false);
      onHide();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);

    try {
      await apiCalls.updateFacility(formData.id, formData);
      await setSuccess(true);
      await onHide();
    } catch (error) {
      setError(error.message);
    }
  };
  const handleGenerateQRCode = async (newdata) => {
    if (newdata) {
      try {
        const qrData = await {type: 'storage_facility', name: newdata.name, id : newdata.id};
        const response = await apiCalls.generateQRCode(qrData);
        var a = document.createElement("a"); //Create <a>
        a.href = "data:image/png;base64," + response.data.image_data; //Image Base64 Goes here
        a.download = `${data.name}.png`; //File name Here
        a.click(); //Downloaded file
        // saveAs(blob, "qrcode.png");
      } catch (error) {
        console.error("Error generating QR code:", error);
      }
    }
  };
  

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && (
        <Alert variant="success">Facility updated successfully!</Alert>
      )}
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter facility name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formLocation">
          <Form.Label>Location</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter location"
            name="location"
            value={formData.location}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter description"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </Form.Group>

        <Stack direction="horizontal" gap={3}>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
          <Button variant="outline-info" onClick={(e) => handleGenerateQRCode(data)}>
            QR-Code
          </Button>
          <Button
            variant="outline-danger"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete
          </Button>
         
        </Stack>
      </Form>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this facility?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditFacility;
