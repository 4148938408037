import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './Services/AuthContext';
import { AxiosProvider } from './Services/AxiosContext';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { FlashMessageProvider } from './Components /FlashMessageContext';
import { CookiesProvider } from 'react-cookie';
import './index.css'


createRoot(document.getElementById('root')).render(
  <FlashMessageProvider>
    <ReactNotifications />
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
    <AuthProvider>
      <AxiosProvider>
        <Router>
          <App />
        </Router>
      </AxiosProvider>
    </AuthProvider>
    </CookiesProvider>
  </FlashMessageProvider>
);
