import React, { useState, useEffect } from "react";
import { Form, Button, Stack, Alert, Row, Col } from "react-bootstrap";
import apiCalls from "../../Services/AxiosCalls";
import { useFlashMessage } from "../../Components /FlashMessageContext";
import Select from "react-select";
import DataTable from "../../Components /DataTable";
import EditModal from "./EditModal";
import ExportCSV from "./ExportCSV";

const RackForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    max_shelves: null, // Default value set to 1
    storage_facility: null,
  });
  const [options, setOptions] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [racks, setRacks] = useState([]); // State variable to toggle between form and DataTable
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedRow, setSelectedRowId] = useState(null);
  const [downloadData, setDownloadData] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      storage_facility: selectedOption ? selectedOption.value : null,
    }));
  };
  const fetchData = async () => {
    try {
      const storageFacilityData = await apiCalls.getStorageFacility();
      const racksData = await apiCalls.getRacks();
      console.log(racksData.data);
      const facilityData = storageFacilityData.data.map((data) => ({
        label: data.name,
        value: data.id,
      }));
      const items = {
        items: racksData?.data.map((data) => ({
          title: `Rack ${data.name} of ${data.storage_facility_name}`,
          data: JSON.stringify({
            key: "Rack",
            id: data.id,
            name: data.name,
          }).replace(/"/g, '""'), // Properly escape quotes
          description: data.description,
        })),
      };
      setDownloadData(items);
      setOptions(facilityData);
      setRacks(racksData.data);
    } catch (error) {
      console.error("Error fetching storage facility data:", error);
      setError(error.response.message);
    }
  };

  useEffect(() => {
    fetchData(); // Call the async function inside useEffect
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);

    try {
      const response = await apiCalls.postRack(formData);
      setSuccess(true);
      fetchData();
      console.log("Form submitted:", response.data);
    } catch (error) {
      console.error("Error submitting form:", error);
      setError(error.response.message);
    }
  };

  const handleToggle = () => {
    setShowForm((prevShowForm) => !prevShowForm);
    // Toggle between showing form and DataTable
  };
  const headers = ["name", "max_shelves", "storage_facility_name"];

  const handleRowClick = (rowData) => {
    console.log(rowData);
    setOpenEdit(true);
    setSelectedRowId(rowData);
  };

  const toggleEditModal = () => {
    setOpenEdit((prevOpenEdit) => !prevOpenEdit);
    fetchData();
  };
  
  return (
    <>
      <Row className="justify-content-between">
        <Col md={4}>
          <h5>Rack</h5>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            variant="link"
            onClick={handleToggle}
            className="d-flex justify-content-end"
          >
            {showForm ? "EDIT RACK" : "CREATE RACK"}{" "}
          </Button>
        </Col>
      </Row>
      {showForm ? (
        <>
          {error && <Alert variant="danger">{error}</Alert>}
          {success && <Alert variant="success">Rack saved successfully!</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter rack name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formLocation">
              <Form.Label>Number of Shelves</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter number of shelves"
                name="max_shelves"
                value={formData.max_shelves}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group>
              <Select
                options={options}
                onChange={handleSelectChange}
                placeholder="Select Storage Facility"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter description"
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </Form.Group>

            <Stack direction="horizontal" gap={3}>
              <Button variant="primary" type="submit">
                Submit
              </Button>
              {/* <Button variant="outline-danger">Cancel</Button> */}
            </Stack>
          </Form>
        </>
      ) : (
        <>
          
          <ExportCSV data={downloadData} />
          <DataTable
            headers={headers}
            data={racks}
            onRowClick={handleRowClick}
          />
        </>
      )}

      {openEdit && (
        <EditModal
          show={openEdit}
          onHide={toggleEditModal}
          resourceType={"Rack"}
          resourceData={selectedRow}
          title={selectedRow.name}
        />
      )}
    </>
  );
};

export default RackForm;
