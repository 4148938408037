import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    accessToken: null,
    refreshToken: null,
    authenticated: false,
  });

  useEffect(() => {
    // Load tokens from localStorage when initializing
    const storedTokens = JSON.parse(localStorage.getItem('authTokens'));
    // console.log(storedTokens);
    if (storedTokens) {
      setAuthState({
        accessToken: storedTokens.accessToken,
        refreshToken: storedTokens.refreshToken,
        authenticated: true,
      });
    }
  }, []);

  const getAccessToken = () => authState.accessToken;

  const logout = () => {
    // Clear auth tokens and state
    localStorage.removeItem('authTokens');
    setAuthState({
      accessToken: null,
      refreshToken: null,
      authenticated: false,
    });
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState, getAccessToken, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export { AuthContext };
