const Pagination = ({
  totalPages,
  currentPage,
  handlePageChange,
  canGoToPreviousPage,
  canGoToNextPage,
  canShowNext,
  canShowPrevious,
}) => {
  const maxPagesToShow = 5;

  const renderPages = () => {
    const pages = [];

    // Display all pages if totalPages is less than or equal to maxPagesToShow
    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`page-button ${currentPage === i ? "active" : ""}`}
          >
            {i}
          </button>
        );
      }
    } else {
      // Display a range of pages with ellipsis
      const startPage = Math.max(
        1,
        currentPage - Math.floor(maxPagesToShow / 2)
      );
      const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

      if (startPage > 1) {
        pages.push(<span key="ellipsis-start">...</span>);
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`page-button ${currentPage === i ? "active" : ""}`}
          >
            {i}
          </button>
        );
      }

      if (endPage < totalPages) {
        pages.push(<span key="ellipsis-end">...</span>);
      }
    }

    return pages;
  };

  return (
    <div className="pagination">
      {canShowPrevious !== null && (
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={!canGoToPreviousPage}
          className="page-button"
        >
          Previous
        </button>
      )}

      {renderPages()}
      {canShowNext !== null && (
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={!canGoToNextPage}
          className="page-button"
        >
          Next
        </button>
      )}
    </div>
  );
};
export default Pagination;
