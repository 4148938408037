import React from "react";
import { Container } from "react-bootstrap";
import classNames from "classnames";
import NavBar from "../content/Navbar";
import AppRoutes from "../Routers/Routes";
import FlashMessage from "../Components /FlashMessage";

const Navbar = (props) => {
  // console.log(JSON.stringify(localStorage.getItem('user_name')))
  return (
    <Container
      fluid
      className={classNames("content", { "is-open": props.isOpen })}
    >
      <FlashMessage />

      <NavBar toggle={props.toggle} />
      <AppRoutes />
    </Container>
  );
};

export default Navbar;
