import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

const UserForm = ({ user, onSubmit }) => {
  const [formData, setFormData] = useState(user);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="first_name" className="mb-3">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          type="text"
          name="first_name"
          value={formData.first_name}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="last_name" className="mb-3">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          type="text"
          name="last_name"
          value={formData.username}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="username" className="mb-3">
        <Form.Label>Username</Form.Label>
        <Form.Control
          type="text"
          name="username"
          value={formData.username}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="email" className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="is_superuser" className="mb-3">
        <Form.Check
          type="switch"
          name="is_superuser"
          label="Has all permissions"
          checked={formData.is_superuser}
          onChange={(e) =>
            setFormData({ ...formData, is_superuser: e.target.checked })
          }
        />
      </Form.Group>
      <Form.Group controlId="is_active" className="mb-3">
        <Form.Check
          type="switch"
          name="is_active"
          label="Is Active"
          checked={formData.is_active}
          onChange={(e) =>
            setFormData({ ...formData, is_active: e.target.checked })
          }
        />
      </Form.Group>
      
    </Form>
  );
};

export default UserForm;
