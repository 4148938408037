import React from 'react';

const Data = () => {
  return (
    <div >
      {/* Navbar content goes here */}
     Data
    </div>
  );
}

export default Data;
