import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@mui/styles'; 
import { createTheme } from '@mui/material/styles'; 

// Create a Material-UI theme
const theme = createTheme();

const useStyles = makeStyles(() => ({
  card: {
    minWidth: 300,
    maxWidth: 300,
    margin: theme.spacing(2.2),
    borderRadius: theme.spacing(1),
    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.2)',
    cursor: 'pointer',
    transition: 'transform 0.2s',
    display: 'flex', // Make the card a flex container
    '&:hover': {
      transform: 'translateY(-5px)',
    },
    // borderLeft: '5px solid #dc3545', // Red border for the card
    height: '90%',
    marginTop: '10px',
    

  
  },
  cardBody: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    color: '#5a5c69',
    height: '100%',
  },
  title: {
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  content: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#5a5c69',
  },
  icon: {
    fontSize: '2rem',
    color: '#e2e6ea',
  },
}));

const SettingsCard = ({ icon, name, color, count }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} style={{borderLeft:`5px solid ${color}`}}>
      <Card.Body className={classes.cardBody}>
        <Row className="w-100 no-gutters align-items-center">
          <Col>
            <div className={classes.title} style={{color:color}}>{name}</div>
            <div className={classes.content}>{count}</div>
          </Col>
          <Col xs="auto">
          {icon && React.cloneElement(icon, { className: classes.icon })}

          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SettingsCard;
