import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import SideBar from "./SideBarNavBar.js/SIdebar";
import Navbar from "./SideBarNavBar.js/Navbar";
import Login from "./Login&Logout/Login";
import { AuthContext } from "./Services/AuthContext";
import AppRoutes from "./Routers/AppRoutes";

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const authContext = useContext(AuthContext);
  let previousWidth = -1;

  const updateWidth = () => {
    const width = window.innerWidth;
    const widthLimit = 576;
    const isMobile = width <= widthLimit;
    const wasMobile = previousWidth <= widthLimit;

    if (isMobile !== wasMobile) {
      setIsOpen(!isMobile);
    }

    previousWidth = width;
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []); // Empty dependency array ensures this runs only on mount and unmount

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="App wrapper">
      {authContext.authState.authenticated ? (
        <>
          <SideBar toggle={toggle} isOpen={isOpen} />
          <Navbar toggle={toggle} isOpen={isOpen} />
          <AppRoutes /> {/* Render your routes here */}
        </>
      ) : (
        <Login /> // Render the Login component when not authenticated
      )}
    </div>
  );
};

export default App;
