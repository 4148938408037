import React, { useEffect, useState } from "react";
import { Offcanvas, Form, Button, Alert, Container } from "react-bootstrap";
import Select from "react-select";
import apiCalls from "../Services/AxiosCalls";
import QRScanner from "./QRScanner";

const SaveInventory = ({ show, handleClose, data }) => {
  const [formData, setFormData] = useState({
    item: null,
    task: null,
    seed_amount: null,
    location_shelf: null,
    location_box: null,
    notes: "",
    expiring_date: null,
    seed_info: "",
    threshold: null,
    unit: "",
  });
  const [storage_facilities, setStorageFacilities] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [selectedRack, setSelectedRack] = useState(null);
  const [selectedShelf, setSelectedShelf] = useState(null);
  const [selectedBox, setSelectedBox] = useState(null);
  const [error, setError] = useState(false);
  const [amount_optons, setAmountOptons] = useState([]);
  const [seedData, setSeedData] = useState(data);
  const [success, setSuccess] = useState(false);

  const weightUnits = [
    { label: "micrograms", value: "micrograms" },
    { label: "milligrams", value: "milligrams" },
    { label: "grams", value: "grams" },
    { label: "kilograms", value: "kilograms" },
    { label: "metric tons", value: "metric tons" },
    { label: "grains", value: "grains" },
    { label: "drachms", value: "drachms" },
    { label: "ounces", value: "ounces" },
    { label: "pounds", value: "pounds" },
    { label: "stones", value: "stones" },
    { label: "quarters", value: "quarters" },
    { label: "hundredweights", value: "hundredweights" },
    { label: "short tons", value: "short tons" },
    { label: "long tons", value: "long tons" },
  ];

  const handleSelectChange = (selectedOption, fieldName) => {
    setFormData({ ...formData, [fieldName]: selectedOption });
  };
  useEffect(() => {
    const fetchStorageFacilities = async () => {
      //   setLoading(true);
      try {
        const response = await apiCalls.getStorageFacility();
        setStorageFacilities(response.data);
        console.log(data);
      } catch (error) {
        setError("Failed to fetch storage facilities.");
      } finally {
        // setLoading(false);
      }
    };

    fetchStorageFacilities();
    seedAmountOptions();
  }, []);

  const seedAmountOptions = async () => {
    const keys = Object.keys(data[0]);
    const options = keys.map((key) => ({
      value: key,
      label: key,
    }));
    console.log(options);
    setAmountOptons(options);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create an array to hold all the promises returned by apiCalls.postInventory
    const saveInventoryPromises = [];

    // Iterate over each item in the data array
    data.forEach((item) => {
      // Extract necessary data from the item and formData
      const inventoryData = {
        location_box: formData.location_box,
        location_shelf: formData.location_shelf,
        task: item.task,
        seed_amount: item[formData.seed_amount],
        seed_info: formData.seed_info,
        notes: formData.notes,
        expiring_date: formData.expiring_date,
        threshold: formData.threshold,
        unit: formData.unit,
      };
      console.log(inventoryData);

      // Call apiCalls.postInventory and push the returned promise to the array
      saveInventoryPromises.push(apiCalls.postInventory(inventoryData));
    });

    try {
      // Wait for all promises to resolve
      const saveInventoryResponses = await Promise.all(saveInventoryPromises);

      // Log the responses
      console.log(saveInventoryResponses);
      setSuccess(true);
      // console.log("Saving inventory data:", selectedBox.value);
      console.log("Saving data:", data);
      setStorageFacilities([]);

    } catch (error) {
      setError(true);
      console.error("Error saving inventory:", error);
    } finally {
      setFormData({
        ...formData,
        item: null,
        task: null,
        seed_amount: null,
        location_shelf: null,
        location_box: null,
        notes: "",
        expiring_date: null,
        seed_info: "",
        threshold: null,
        units: null,
      });
    }

    // handleClose();
  };

  const handleFacilityChange = (selectedOption) => {
    setSelectedFacility(selectedOption);
    setSelectedRack(null);
    setSelectedShelf(null);
    setSelectedBox(null);
  };

  const handleRackChange = (selectedOption) => {
    setSelectedRack(selectedOption);
    setSelectedShelf(null);
    setSelectedBox(null);
  };
  const handleAmount = (selectedOption) => {
    setFormData({ ...formData, seed_amount: selectedOption.value });

    console.log(selectedOption);
  };

  const handleShelfChange = (selectedOption) => {
    setSelectedShelf(selectedOption);
    setFormData({ ...formData, location_shelf: selectedOption.value });

    setSelectedBox(null);
  };

  const handleBoxChange = (selectedOption) => {
    setSelectedBox(selectedOption);
    setFormData({
      ...formData,
      location_box: selectedOption.value,
      location_shelf: null,
    });
  };
  const handleUnitChange = (selectedOption) => {
    setFormData({
      ...formData,
      unit: selectedOption.value,
    });
  };

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      style={{ width: "60%", borderRadius: "20px" }}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <Button variant="outline-primary" disabled>
            Save Inventory
          </Button>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body
        style={{ maxHeight: "calc(100vh - 120px)", overflowY: "auto" }}
      >
        {error && <Alert variant="danger">{"Something went wrong"}</Alert>}
        {success && (
          <Alert variant="success">{"Inventory created successfully"}</Alert>
        )}
        <Container>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Storage Facility</Form.Label>
              {/* Replace options with actual data */}
              <Select
                value={selectedFacility}
                onChange={handleFacilityChange}
                options={storage_facilities.map((facility) => ({
                  value: facility.id,
                  label: facility.name,
                }))}
                placeholder="Select Facility"
              />
            </Form.Group>
            {selectedFacility && (
              <Form.Group className="mb-3">
                <Select
                  value={selectedRack}
                  onChange={handleRackChange}
                  options={storage_facilities
                    .find((facility) => facility.id === selectedFacility.value)
                    ?.racks.map((rack) => ({
                      value: rack.id,
                      label: rack.name,
                    }))}
                  placeholder="Select Rack"
                />
              </Form.Group>
            )}
            {selectedRack && (
              <Form.Group className="mb-3">
                <Select
                  value={selectedShelf}
                  onChange={handleShelfChange}
                  options={storage_facilities
                    .find((facility) => facility.id === selectedFacility.value)
                    ?.racks.find((rack) => rack.id === selectedRack.value)
                    ?.shelves.map((shelf) => ({
                      value: shelf.id,
                      label: `${shelf.name}`,
                    }))}
                  placeholder="Select Shelf"
                />
              </Form.Group>
            )}
            {selectedShelf && (
              <Form.Group className="mb-3">
                <Select
                  value={selectedBox}
                  onChange={handleBoxChange}
                  options={storage_facilities
                    .find((facility) => facility.id === selectedFacility.value)
                    ?.racks.find((rack) => rack.id === selectedRack.value)
                    ?.shelves.find((shelf) => shelf.id === selectedShelf.value)
                    ?.boxes.map((box) => ({
                      value: box.id,
                      label: `Box number ${box.number}`,
                    }))}
                  placeholder="Select Box"
                />
              </Form.Group>
            )}
            {amount_optons && (
              <Form.Group className="mb-3">
                <Select
                  // value={selectedBox}
                  onChange={handleAmount}
                  options={amount_optons}
                  placeholder="Select the column for the seed amount"
                />
              </Form.Group>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Threshold</Form.Label>
              <Form.Control
                // as="number"
                required
                type="number"
                value={formData.threshold}
                onChange={(e) =>
                  setFormData({ ...formData, threshold: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Units</Form.Label>
              {/* Replace options with actual data */}
              <Select
                value={formData.units}
                onChange={handleUnitChange}
                options={weightUnits}
                placeholder="Select Unit"
                required
                

              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                as="textarea"
                value={formData.notes}
                onChange={(e) =>
                  setFormData({ ...formData, notes: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Expiring Date</Form.Label>
              <Form.Control
                type="date"
                value={formData.expiring_date}
                onChange={(e) =>
                  setFormData({ ...formData, expiring_date: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Seed Info</Form.Label>
              <Form.Control
                as="textarea"
                value={formData.seed_info}
                onChange={(e) =>
                  setFormData({ ...formData, seed_info: e.target.value })
                }
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Form>
        </Container>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SaveInventory;
