import React, { useEffect, useState } from "react";
import apiCalls from "../../Services/AxiosCalls";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import DataTable from "../../Components /DataTable";
import CustomModal from "../../Components /CustomModal";
import { useFlashMessage } from "../../Components /FlashMessageContext";


const Blocks = () => {
      const navigate = useNavigate()

  const [blocks, setBlocks] = useState(null);
  const [stations, setStations] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [updatedName, setUpdatedName] = useState("");
  const [updateStation, setUpdatedStation] = useState(null);
  const [updatedDescription, setUpdatedDescription] = useState("");
  const [viewPorts, setViewPorts] = useState(false);
  const [blocID, setBlockID] = useState(null);
  const [formValue, setFormValue] = useState({
    name: "",
    uses_batch: false,
    description: "",
    station: null,
  });
  const { showFlashMessage } = useFlashMessage();

  useEffect(() => {
    getBlocks();
    getStations();
  }, []);

  const getBlocks = async () => {
    try {
      const blocks = await apiCalls.getAllMachines();
      console.log(blocks.data);
      setBlocks(blocks.data);
    } catch (error) {
      console.error("Error fetching stations:", error);
      showFlashMessage("Error fetching stations", "danger");
    }
  };
  const getStations = async () => {
    try {
      const response = await apiCalls.getAllStations();
      setStations(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching stations:", error);
      showFlashMessage("Error fetching stations", "danger");
    }
  };

  const handleRowClick = (rowData) => {
    console.log(rowData);
    setSelectedRowId(rowData);
    setUpdatedName(rowData.name);
    setUpdatedStation(rowData.station);
    setUpdatedDescription(rowData.description);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCreateCloseModal = () => {
    setCreateModal(false);
  };
  const handlePortCloseModal = () => {
    setViewPorts(false);
  };

  const handleUpdateName = async () => {
    try {
      if (!selectedRowId || !updatedName) {
        console.error("Invalid data for update.");
        showFlashMessage("Invalid data for update", "danger");
        return;
      }

      const updatedData = {
        id: selectedRowId.id,
        name: updatedName,
        description: updatedDescription,
        station: updateStation,
      };
      console.log(updatedData);

      const response = await apiCalls.updateBlock(
        selectedRowId.id,
        updatedData
      );

      if (response.status === 200) {
        showFlashMessage("Station Updated!", "success");
        setShowModal(false);
        getBlocks();
      } else {
        console.error("Unexpected API response:", response);
        showFlashMessage("Error!", "danger");
      }
    } catch (error) {
      console.error("Error updating name:", error);
      showFlashMessage("Error Updating Station!", "danger");
    }
  };

  const handleAddStation = () => {
    setCreateModal(true);
  };

  const handlePortModal = () => {
    setViewPorts(true);
  };

  const handleSaveStation = async () => {
    console.log(formValue);
    try {
      const response = await apiCalls.saveMachine(formValue);
      if (response.status === 201) {
        showFlashMessage("Block Created!", "success");
        setCreateModal(false);
        getBlocks();
      } else {
        console.error("Unexpected API response:", response);
        showFlashMessage("Error Creating Block", "danger");
      }
    } catch (error) {
      console.error("Error creating block:", error);
      showFlashMessage("Error Creating Block!", "danger");
    }
  };
  const handleportView = async () => {
    console.log(blocID);
    try {
      const response = await apiCalls.getblockPorts(blocID);
      console.log(response)
      navigate(`/settings/ports/${blocID}`);
    //   if (response.status === 200) {
    //     showFlashMessage("Block Created!", "success");
    //     setCreateModal(false);
    //     getBlocks();
    //   } else {
    //     console.error("Unexpected API response:", response);
    //     showFlashMessage("Error Creating Block", "danger");
    //   }
    } catch (error) {
      console.error("Error creating block:", error);
      showFlashMessage("Error Creating Block!", "danger");
    }
  };
  const headers = ["name", "description"];

  return (
    <>
      <div className="d-flex justify-content-end mb-3 ">
        <Button
          variant="success"
          onClick={handleAddStation}
          style={{ marginLeft: 12 }}
        >
          Create New
        </Button>
        <Button
          variant="primary"
          onClick={handlePortModal}
          style={{ marginLeft: 12 }}
        >
          View Ports
        </Button>
      </div>
      {blocks && (
        <DataTable
          headers={headers}
          data={blocks}
          onRowClick={handleRowClick}
        />
      )}
      <CustomModal
        show={showModal}
        onHide={handleCloseModal}
        title="Edit Block"
        content={
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={updatedName}
                onChange={(e) => setUpdatedName(e.target.value)}
              />
            </Form.Group>
            {stations && (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Station</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) =>
                    setUpdatedStation(parseFloat(e.target.value))
                  }
                  placeholder="Select Type"
                  value={updateStation} // Set the default value to the desired station's ID
                >
                  <option value="">Station type</option>
                  {stations.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Description"
                value={updatedDescription}
                onChange={(e) => setUpdatedDescription(e.target.value)}
              />
            </Form.Group>
          </Form>
        }
        btnName="Update"
        onSave={handleUpdateName}
      />
      <CustomModal
        show={createModal}
        onHide={handleCreateCloseModal}
        title="Create Block"
        content={
          <Form  noValidate validated="false">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Block name"
                value={formValue.name}
                onChange={(e) =>
                  setFormValue({ ...formValue, name: e.target.value })
                }
                required
              />
            </Form.Group>
            {stations && (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) =>
                    setFormValue({ ...formValue, station: e.target.value })
                  }
                  placeholder="Select Type"
                  required
                >
                  <option value="">Station type</option>
                  {stations.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({ ...formValue, description: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        }
        btnName="Save"
        onSave={handleSaveStation}
      />
      <CustomModal
        show={viewPorts}
        onHide={handlePortCloseModal}
        title="Select Block "
        content={
          <Form>
            {blocks && (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setBlockID(e.target.value)}
                  placeholder="Select Type"
                >
                  <option value="">Select Block</option>
                  {blocks.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}
          </Form>
        }
        btnName="Get Ports"
        onSave={handleportView}
      />
    </>
  );
};

export default Blocks;
