import React, { useEffect, useState } from "react";
import apiCalls from "../../Services/AxiosCalls";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import DataTable from "../../Components /DataTable";
import CustomModal from "../../Components /CustomModal";
import { useFlashMessage } from "../../Components /FlashMessageContext";

const Stations = () => {
  const [stations, setStations] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [updatedName, setUpdatedName] = useState("");
  const [updatedDescription, setUpdatedDescription] = useState("");

  const [formValue, setFormValue] = useState({
    name: "",
    type: "",
    description: "",
  });
  const { showFlashMessage } = useFlashMessage();

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const response = await apiCalls.getAllStations();
      console.log(response)
      setStations(response.data);
    } catch (error) {
      console.error("Error fetching stations:", error);
      showFlashMessage("Error fetching stations", "danger");
    }
  };

  const handleRowClick = (rowData) => {
    setSelectedRowId(rowData);
    setUpdatedName(rowData.name);
    setUpdatedDescription(rowData.description);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCreateCloseModal = () => {
    setCreateModal(false);
  };

  const handleUpdateName = async () => {
    try {
      if (!selectedRowId || !updatedName) {
        console.error("Invalid data for update.");
        showFlashMessage("Invalid data for update", "danger");
        return;
      }

      const updatedData = {
        id: selectedRowId.id,
        name: updatedName,
        type: selectedRowId.type,
        description: updatedDescription,
        department: selectedRowId.department,
      };

      const response = await apiCalls.updateStation(
        selectedRowId.id,
        updatedData
      );

      if (response.status === 200) {
        showFlashMessage("Station Updated!", "success");
        setShowModal(false);
        getUsers();
      } else {
        console.error("Unexpected API response:", response);
        showFlashMessage("Error!", "danger");
      }
    } catch (error) {
      console.error("Error updating name:", error);
      showFlashMessage("Error Updating Station!", "danger");
    }
  };

  const handleAddStation = () => {
    setCreateModal(true);
  };

  const handleSaveStation = async () => {
    console.log(formValue);
    try {
      const response = await apiCalls.saveStation(formValue);
      if (response.status === 201) {
        showFlashMessage("Station Created!", "success");
        setCreateModal(false);
        getUsers();
      } else {
        console.error("Unexpected API response:", response);
        showFlashMessage("Error Creating Station", "danger");
      }
    } catch (error) {
      console.error("Error creating station:", error);
      showFlashMessage("Error Creating Station!", "danger");
    }
  };
  const headers = ["name", "description","type"];

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <Button variant="success" onClick={handleAddStation}>
          Create New
        </Button>
      </div>
      {stations && (
        <DataTable
          headers={headers}
          data={stations}
          onRowClick={handleRowClick}
        />
      )}
      <CustomModal
        show={showModal}
        onHide={handleCloseModal}
        title="Edit Station Name"
        content={
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={updatedName}
                onChange={(e) => setUpdatedName(e.target.value)}
              />
              <Form.Text className="text-muted">
                You can edit and update the Station name
              </Form.Text>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Description"
                value={updatedDescription}
                onChange={(e) => setUpdatedDescription(e.target.value)}
              />
            </Form.Group>
          </Form>
        }
        btnName="Update"
        onSave={handleUpdateName}
      />
      <CustomModal
        show={createModal}
        onHide={handleCreateCloseModal}
        title="Create Station"
        content={
          <Form noValidate validated="false">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Station name"
                value={formValue.name}
                onChange={(e) =>
                  setFormValue({ ...formValue, name: e.target.value })
                }
                required
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Select
                aria-label="Default select example"
                onChange={(e) =>
                  setFormValue({ ...formValue, type: e.target.value })
                }
                placeholder="Select Type"
                required
              >
                <option value="">Station type</option>
                <option value="BL">Block</option>
                <option value="IM">Image</option>
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Description"
                value={formValue.description}
                onChange={(e) =>
                  setFormValue({ ...formValue, description: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        }
        btnName="Save"
        onSave={handleSaveStation}
      />
    </>
  );
};

export default Stations;
