import React, { useState, useEffect } from "react";
import { Form, Button, Stack, Alert, Modal } from "react-bootstrap";
import apiCalls from "../../Services/AxiosCalls";
import Select from "react-select";

const EditBox = ({ data, onHide }) => {
  const [formData, setFormData] = useState(data);
  const [options, setOptions] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      shelf: selectedOption ? selectedOption.value : null,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const shelves = await apiCalls.getShelves();

        const shelfData = shelves.data.map((data) => ({
          label: `Shelf ${data.number} of Rack ${data.rack_name}`,
          value: data.id,
        }));
        setOptions(shelfData);
      } catch (error) {
        console.error("Error fetching shelf data:", error);
        setError("Error fetching shelf data");
      }
    };

    fetchData(); // Call the async function inside useEffect
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);

    try {
      const response = await apiCalls.updateBox(formData.id, formData);
      setSuccess(true);
      console.log("Form submitted:", response.data);
    } catch (error) {
      console.error("Error submitting form:", error);
      setError(error.response.message || "Failed to update box");
    }
  };

  const handleDelete = async () => {
    try {
      await apiCalls.deleteBox(formData.id);
      setShowDeleteModal(false);
      onHide();
    } catch (error) {
      console.error("Error deleting box:", error);
      setError(error.response.message || "Failed to delete box");
    }
  };
  const handleGenerateQRCode = async (newdata) => {
    if (newdata) {
        try {
            const qrData = {
                type: "Box",
                rack_name: newdata.rack_name,
                shelf_number: newdata.shelf_number,
                id: newdata.id,
                number: newdata.number,
            };
            const response = await apiCalls.generateQRCode(qrData);
            const a = document.createElement("a");
            a.href = "data:image/png;base64," + response.data.image_data;
            a.download = `Box Number ${newdata.number}.png`;
            a.title = `Box Number ${newdata.number}.png`; // Set title attribute
            a.click();
        } catch (error) {
            console.error("Error generating QR code:", error);
        }
    }
};


  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">Box updated successfully!</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Select
            options={options}
            onChange={handleSelectChange}
            placeholder="Select Shelf"
            value={options.find((option) => option.value === formData.shelf)}
            isClearable
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formNumber">
          <Form.Label>Box Number</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter box number"
            name="number"
            value={formData.number}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter description"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </Form.Group>

        <Stack direction="horizontal" gap={3}>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
          <Button
            variant="outline-info"
            onClick={(e) => handleGenerateQRCode(data)}
          >
            QR-Code
          </Button>
          <Button
            variant="outline-danger"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete
          </Button>
        </Stack>
      </Form>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this box?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditBox;
