import React from 'react';
import { CSVLink } from 'react-csv';

const ExportCSV = ({ data }) => {
  const headers = [
    { label: "Title", key: "title" },
    { label: "Data", key: "data" },
    { label: "Description", key: "description" }
  ];

  const csvData = data.items.map(item => ({
    title: item.title,
    data: item.data, // Already serialized and properly escaped
    description: item.description
  }));

  return (
    <CSVLink data={csvData} headers={headers} filename={"data.csv"}>Export CSV</CSVLink>
  );
};

export default ExportCSV;
