import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import EditFacility from "./EditFacility";
import EditRack from "./EditRack";
import EditShelf from "./EditShelve";
import EditBox from "./EditBox";

const EditModal = ({
  show,
  onHide,
  resourceType,
  resourceData,
  onSubmit,
  title,
  fetchData,
}) => {
  const [formData, setFormData] = useState(resourceData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {resourceType === "Facility" && (
          <EditFacility data={formData} onSubmit={onSubmit} onHide={onHide} />
        )}
        {resourceType === "Rack" && (
          <EditRack data={formData} onSubmit={onSubmit} onHide={onHide} />
        )}
        {resourceType === "Shelves" && (
          <EditShelf data={formData} onSubmit={onSubmit} onHide={onHide} />
        )}
        {resourceType === "Boxes" && (
          <EditBox data={formData} onSubmit={onSubmit} onHide={onHide} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;
