import React, { useState, useEffect } from "react";
import { Form, Button, Stack, Alert, Modal } from "react-bootstrap";
import apiCalls from "../../Services/AxiosCalls";
import Select from "react-select";

const EditRack = ({ data, onHide }) => {
  const [formData, setFormData] = useState(data);
  const [options, setOptions] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      storage_facility: selectedOption ? selectedOption.value : null,
    }));
  };
  const handleGenerateQRCode = async (newdata) => {
    if (newdata) {
      try {
        const qrData = await {type: 'Rack', name: newdata.name, id : newdata.id};
        const response = await apiCalls.generateQRCode(qrData);
        var a = document.createElement("a"); //Create <a>
        a.href = "data:image/png;base64," + response.data.image_data; //Image Base64 Goes here
        a.download = `${data.name}.png`; //File name Here
        a.click(); //Downloaded file
        // saveAs(blob, "qrcode.png");
      } catch (error) {
        console.error("Error generating QR code:", error);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storageFacilityData = await apiCalls.getStorageFacility();
        const facilityData = storageFacilityData.data.map((data) => ({
          label: data.name,
          value: data.id,
        }));
        setOptions(facilityData);
      } catch (error) {
        console.error("Error fetching storage facility data:", error);
        setError(error.response.message || "Failed to fetch storage facility data");
      }
    };

    fetchData(); // Call the async function inside useEffect
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess(false);

    try {
      const response = await apiCalls.updateRack(formData.id, formData);
      setSuccess(true);
      console.log("Form submitted:", response.data);
      onHide()
    } catch (error) {
      console.error("Error submitting form:", error);
      setError(error.response.message || "Failed to update rack");
    }
  };

  const handleDelete = async () => {
    try {
      await apiCalls.deleteRack(formData.id);
      setShowDeleteModal(false);
      onHide()
    } catch (error) {
      console.error("Error deleting rack:", error);
      setError(error.response.message || "Failed to delete rack");
    }
  };

  return (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">Rack updated successfully!</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter rack name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formLocation">
          <Form.Label>Number of Shelves</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter number of shelves"
            name="max_shelves"
            value={formData.max_shelves}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group>
          <Select
            options={options}
            onChange={handleSelectChange}
            value={options.find((option) => option.value === formData.storage_facility)}
            placeholder="Select Storage Facility"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter description"
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
        </Form.Group>

        <Stack direction="horizontal" gap={3}>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
          <Button variant="outline-info" onClick={(e) => handleGenerateQRCode(data)}>
            QR-Code
          </Button>
          <Button variant="outline-danger" onClick={() => setShowDeleteModal(true)}>Delete</Button>
        </Stack>
      </Form>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this rack?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={handleDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditRack;
