import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import QrReader from "react-qr-scanner";

const QRScannerModal = ({ show, handleClose, getData }) => {
  const [delay] = useState(100);
  const [result, setResult] = useState("No result");
  const [facingMode, setFacingMode] = useState("environment"); // 'user' for front camera, 'environment' for rear camera

  const handleScan = (data) => {
    if (data != null) {
      console.log(data);
      const validJsonString = data.text.replace(/'/g, '"');
      const jsonObject = JSON.parse(validJsonString);
      console.log(jsonObject);

      setResult(jsonObject);
      getData(jsonObject)
      handleClose();
    }
  };
  const [width, setWidth] = useState(window.innerWidth);

function handleWindowSizeChange() {
    setWidth(window.innerWidth);
}

  const handleError = (err) => {
    console.error(err);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);
  const toggleCamera = () => {
    setFacingMode(facingMode === "environment" ? "user" : "environment");
  };

  const previewStyle = {
    height: 240,
    width: 320,
  };
  let isDesktop = (width > 768);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>QR Scanner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <QrReader
          delay={delay}
          style={previewStyle}
          onError={handleError}
          onScan={handleScan}
          // facingMode={facingMode}
          // legacyMode={true}
          constraints={
            isDesktop
            ? undefined
            : {
                video: {
                    facingMode: { exact: `environment` }
                }
              }
        }
        />
        <p>{result}</p>
        <Button variant="primary" onClick={toggleCamera}>
          Switch Camera
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default QRScannerModal;
