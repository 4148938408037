import React from 'react';
import { Card, ProgressBar, Badge } from 'react-bootstrap';
import '../CSS_Files/ProjectCard.css'

const ProjectCard = ({ project, onClick }) => {
  const { name, description, deadline, progress } = project;

  const getStatusVariant = () => {
    const today = new Date();
    const deadlineDate = new Date(deadline);

    if (deadlineDate < today) {
      return 'danger'; // Deadline date is before today, so it's delayed.
    } else if (deadlineDate > today) {
      return 'info'; // Deadline date is after today, so it's ongoing.
    } else {
      return 'success'; // Deadline date is today, so it's completed.
    }
  };

  return (
    <Card className="mb-3" onClick={onClick} style={{ cursor: 'pointer' }}>
      <Card.Body>
        <Card.Title className="text-truncate">{name}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{description}</Card.Subtitle>
        {/* <Badge variant={getStatusVariant()} className="mb-3">
          {getStatusVariant() === 'danger' ? 'Delayed' : getStatusVariant() === 'info' ? 'Ongoing' : 'Completed'}
        </Badge> */}
        {/* <Card.Text>
          Deadline: {new Date(deadline).toLocaleDateString()}
        </Card.Text> */}
        {/* <ProgressBar now={progress} label={`${progress}%`} variant={getStatusVariant()} /> */}
      </Card.Body>
    </Card>
  );
};

export default ProjectCard;
