// ProcessTrendReport.jsx
import React, { useState, useEffect } from "react";
import { Bar, Chart } from "react-chartjs-2";
import axios from "axios";
import apiCalls from "../Services/AxiosCalls";
import { Col, Row } from "react-bootstrap";

const ProcessTrendReport = ({ time, project }) => {
  const [trendData, setTrendData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      console.log(time + " " + project);
      try {
        const response = await apiCalls.getProcessPerBlock({
          time_interval: time,
          project_id: project,
        });
        setTrendData(response.data);
      } catch (error) {
        console.error("Error fetching trend data:", error);
      }
    };
    // const fetchDataRecords = async () => {
    //   try {
    //     const response = await apiCalls.getRecordsPerCount('month');
    //     console.log(response.data)
    //     // setTrendData(response.data);
    //   } catch (error) {
    //     console.error("Error fetching trend data:", error);
    //   }
    // };

    fetchData();
  }, [time, project]);

  // Extract data for chart rendering
  const chartData = trendData?.data || [];

  // Combine data for blocks with the same name and sum the process counts
  const combinedChartData = chartData.reduce((combined, entry) => {
    const existingEntry = combined.find(
      (item) => item.block__name === entry.block__name
    );

    if (existingEntry) {
      existingEntry.process_count += entry.process_counts.reduce(
        (sum, count) => sum + count,
        0
      );
    } else {
      combined.push({
        block__name: entry.block__name,
        process_count: entry.process_counts.reduce(
          (sum, count) => sum + count,
          0
        ),
      });
    }

    return combined;
  }, []);

  const chartLabels = combinedChartData.map((entry) => entry.block__name);
  const chartDatasets = [
    {
      label: "Total Processes",
      data: combinedChartData.map((entry) => entry.process_count),
      backgroundColor: " #36B9CC",
      borderColor: " #36B9CC",
      borderWidth: 1,
    },
  ];

  // Example chart options (customize as needed)
  const chartOptions = {
    scales: {
      x: { title: { display: true, text: "Block Name" } },
      y: { title: { display: true, text: "Total Number of Processes" } },
    },
    maintainAspectRatio: true, // Set to false to allow resizing
    responsive: true,
  };

  return (
    <div>
      <h5  className="d-flex justify-content-center align-items-center">Processes per Block Trend Report</h5>
      {trendData && (
        <Chart
          type="bar"
          data={{ labels: chartLabels, datasets: chartDatasets }}
          options={chartOptions}
        />
      )}
    </div>
  );
};

export default ProcessTrendReport;
