import React from 'react';
import { Form, InputGroup, FormControl, Button } from 'react-bootstrap';

const SearchBar = ({ placeholder, onChange }) => {
  return (
    <Form>
      <InputGroup className="mb-3">
        <FormControl
          type="text"
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
        />
       
      </InputGroup>
    </Form>
  );
};

export default SearchBar;
